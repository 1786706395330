import React from "react";
import { Link } from "react-router-dom";
import "./TextLogo.scss";

const TextLogo = () => (
  <div className="text-center Logo">
    <Link to="/">
      <img
        className="img-responsive"
        src="/logo512.png"
        width="144px"
        alt="Logo"
      />
      <h2 className="my-3">Value Navigator</h2>
    </Link>
  </div>
);

export default TextLogo;
