import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import { useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { destroy } from "utils/DeApi";

const DeleteActivity = ({ activityId, refreshList, title }) => {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const handleDelete = () => {
    setIsLoading(true);
    const deleteActivityPromise = destroy(`/activities/${activityId}`);

    deleteActivityPromise.promise
      .then((response) => {
        setShow(false);
        refreshList();
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <Button
        onClick={() => {
          setShow(true);
        }}
        size="sm"
        className="px-2 py-0"
        variant="outline-danger"
      >
        <span className="material-icons-outlined md-18">delete</span>
      </Button>

      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Activity - {title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete the activity?</Modal.Body>
        <Modal.Footer>
          <Button
            size="sm"
            variant="secondary"
            onClick={() => {
              setShow(false);
            }}
          >
            Cancel
          </Button>
          <Button
            size="sm"
            variant="danger"
            disabled={isLoading}
            onClick={handleDelete}
          >
            {isLoading && (
              <Spinner
                className="me-2"
                animation="border"
                size="sm"
                variant="light"
              />
            )}
            Yes, I want to delete the activity
          </Button>
        </Modal.Footer>
        {error && <ErrorHandler error={error} />}
      </Modal>
    </>
  );
};

export default DeleteActivity;
