import { Button, Form, Spinner } from "react-bootstrap";
import DeleteActivity from "./DeleteActivity";
import { useState } from "react";
import { put } from "utils/DeApi";

const SingleActivity = ({
  activity,
  phaseIndex,
  activityIndex,
  refreshList,
}) => {
  const { id, title, updatedAt, createdAt } = activity;

  const [isEditable, setIsEditable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [newTitle, setNewTitle] = useState(title);

  const handleTitleSubmit = (title) => {
    setIsLoading(true);

    const activityTitlePromise = put(`/activities/${id}`, {
      title: title,
      description: "",
    });

    activityTitlePromise.promise
      .then((response) => {
        setIsEditable(false);
        refreshList();
      })
      .catch((error) => {
        // !error.isCanceled && setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });

    // subscribedPromises.current.push(projectInChargePromise);
  };

  return (
    <tr className="align-middle">
      <td>
        {phaseIndex + 1}.{activityIndex + 1}
      </td>
      <td>
        {isEditable ? (
          <Form.Control
            value={newTitle}
            onChange={(e) => {
              setNewTitle(e.target.value);
            }}
          />
        ) : (
          <>{title}</>
        )}
      </td>
      <td className="text-end">
        {isEditable ? (
          <>
            <Button
              className="px-2 py-0 me-1"
              size="sm"
              variant="outline-success"
              disabled={isLoading}
              onClick={() => {
                handleTitleSubmit(newTitle);
              }}
            >
              {isLoading ? (
                <Spinner animation="border" size="sm" />
              ) : (
                <span className="material-icons-outlined md-18">done</span>
              )}
            </Button>
            <Button
              onClick={() => {
                setIsEditable(false);
              }}
              size="sm"
              className="px-2 py-0"
              variant="outline-danger"
            >
              <span className="material-icons-outlined md-18">cancel</span>
            </Button>
          </>
        ) : (
          <>
            <Button
              onClick={() => {
                setIsEditable(true);
              }}
              size="sm"
              className="px-2 py-0 me-1"
              variant="outline-warning"
            >
              <span className="material-icons-outlined md-18">edit</span>
            </Button>
            <DeleteActivity
              activityId={id}
              refreshList={refreshList}
              title={title}
            />
          </>
        )}
      </td>
    </tr>
  );
};

export default SingleActivity;
