import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { get } from "utils/DeApi";
import { OpportunityDispatchContext } from "contexts/OpportunityProvider";

export const useRefreshOpportunityData = () => {
  const [isLoading, setIsLoading] = useState();

  let { opportunityId } = useParams();
  const setOpportunity = useContext(OpportunityDispatchContext);

  const callApi = () => {
    setIsLoading(true);
    const opportunityDetails = get(`/opportunities/${opportunityId}`);

    opportunityDetails.promise
      .then((response) => {
        setOpportunity(response.data);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          // reload the whole page if we couldn't update the opportunities data
          window.location.reload();
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return { callApi, isLoading };
};
