import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import { useRef, useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { put, destroy } from "utils/DeApi";

const SingleEquipment = ({ equipment, index, activityId, updateData }) => {
  const subscribedPromises = useRef([]);
  const [isEditable, setIsEditable] = useState(false);
  const [equipmentChangeError, setEquipmentChangeError] = useState();
  const [equipmentChangeIsLoading, setEquipmentChangeIsLoading] =
    useState(false);
  const [equipmentDeleteError, setEquipmentDeleteError] = useState();
  const [equipmentDeleteIsLoading, setEquipmentDeleteIsLoading] =
    useState(false);

  const {
    id,
    equipmentCategory,
    currencyUnit,
    description,
    quantity,
    reimbursible,
    taxVat,
    taxWht,
    title,
    total,
    updatedAt,
    localCost,
  } = equipment;

  const [newQty, setNewQty] = useState(quantity);

  const handleEquipmentChangeSubmit = () => {
    setEquipmentChangeIsLoading(true);
    const equipmentChangePromise = put(
      `/activities/${activityId}/equipment-supplies/${id}`,
      {
        equipmentQuantity: newQty,
        equipmentTaxVat: 0,
        equipmentTaxWht: 0,
      },
    );

    equipmentChangePromise.promise
      .then((response) => {
        updateData(response.data);
        setEquipmentChangeError(null);
        setIsEditable(false);
      })
      .catch((error) => {
        !error.isCanceled && setEquipmentChangeError(error);
        console.log(error);
      })
      .finally(() => {
        setEquipmentChangeIsLoading(false);
      });

    subscribedPromises.current.push(equipmentChangePromise);
  };

  const handleEquipmentDeleteSubmit = () => {
    setEquipmentDeleteIsLoading(true);
    const equipmentDeletePromise = destroy(
      `/activities/${activityId}/equipment-supplies/${id}`,
    );

    equipmentDeletePromise.promise
      .then((response) => {
        updateData(response.data);
        setEquipmentDeleteError(null);
        setIsEditable(false);
      })
      .catch((error) => {
        !error.isCanceled && setEquipmentDeleteError(error);
      })
      .finally(() => {
        setEquipmentDeleteIsLoading(false);
      });

    subscribedPromises.current.push(equipmentDeletePromise);
  };

  return (
    <>
      <tr key={id} className="align-middle">
        <td>{index + 1}.</td>
        <td className="ps-3">{equipmentCategory?.title}</td>
        <td>{title}</td>
        <td className="text-end">${localCost}</td>
        <td className="text-end" style={{ width: "50px" }}>
          {isEditable ? (
            <Form.Control
              type="number"
              size="sm"
              value={newQty}
              onChange={(e) => {
                setNewQty(e.target.value);
              }}
            />
          ) : (
            <>{quantity}</>
          )}
        </td>
        <td className="text-end">${total}</td>
        <td className="text-end">
          <div className="d-flex flex-row h-100 w-100 justify-content-center">
            {isEditable ? (
              <Button
                size="sm"
                className="px-2 py-0 me-2"
                variant="outline-success"
                onClick={() => {
                  handleEquipmentChangeSubmit();
                }}
              >
                {equipmentChangeIsLoading ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  <span className="material-icons-outlined md-18">done</span>
                )}
              </Button>
            ) : (
              <Button
                size="sm"
                className="px-2 py-0 me-2"
                variant="outline-success"
                onClick={() => {
                  setIsEditable(true);
                }}
              >
                <span className="material-icons-outlined md-18">edit</span>
              </Button>
            )}
            <Button
              size="sm"
              className="px-2 py-0"
              variant="outline-danger"
              onClick={() => {
                handleEquipmentDeleteSubmit();
              }}
            >
              {equipmentDeleteIsLoading ? (
                <Spinner animation="border" size="sm" />
              ) : (
                <span className="material-icons-outlined md-18">delete</span>
              )}
            </Button>
          </div>
        </td>
      </tr>
      <tr>
        {equipmentChangeError && (
          <td colSpan={9999}>
            <ErrorHandler error={equipmentChangeError} />
          </td>
        )}
        {equipmentDeleteError && (
          <td colSpan={9999}>
            <ErrorHandler error={equipmentDeleteError} />
          </td>
        )}
      </tr>
    </>
  );
};

export default SingleEquipment;
