import InputField from "../../Inputs/InputField";
import { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Container, Row, Spinner } from "react-bootstrap";
import { put } from "utils/DeApi";

const ClientInformation = ({
  information,
  opportunityId,
  setOpportunityDetails,
}) => {
  const subscribedPromises = useRef([]);
  const [isEditable, setIsEditable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    client,
    // clientEntityCountry,
    // service,
    // sector,
    // organization
  } = information;

  const [newClient, setNewClient] = useState(client);

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, []);

  const handleClientSubmit = () => {
    setIsLoading(true);
    const clientPromise = put(`/opportunities/${opportunityId}/information`, {
      client: newClient,
    });

    clientPromise.promise
      .then((response) => {
        setOpportunityDetails(response.data);
      })
      .catch((error) => {
        // !error.isCanceled && setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });

    subscribedPromises.current.push(clientPromise);
  };

  return (
    <Card className="my-3">
      <Card.Body>
        <div className="d-flex justify-content-between">
          <h4>Client Information</h4>
          <div className="d-flex">
            {/* {isEditable ? (
              <>
                <Button
                  size="sm"
                  className="px-2 py-0"
                  variant="outline-success"
                  disabled={isLoading}
                  onClick={() => {
                    setIsEditable(false);
                  }}
                >
                  {isLoading ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    <span className="material-icons-outlined md-18">done</span>
                  )}
                </Button>
              </>
            ) : (
              <Button
                size="sm"
                className="px-2 py-0"
                variant="outline-warning"
                onClick={() => {
                  setIsEditable(true);
                }}
              >
                <span className="material-icons-outlined md-18">edit</span>
              </Button>
            )} */}
          </div>
        </div>
        <hr />
        {isEditable ? (
          <Container>
            <Row>
              <Col>
                <InputField
                  value={newClient}
                  label={"Client"}
                  placeholder={"client"}
                  handleChange={setNewClient}
                  onBlur={handleClientSubmit}
                />
              </Col>
            </Row>
          </Container>
        ) : (
          <Container>
            <Row>
              <Col>
                <span
                  // onClick={() => {
                  //   setIsEditable(true);
                  // }}
                  className="text-muted cursor-pointer"
                >
                  Client
                </span>
                <h5>{client}</h5>
              </Col>
            </Row>
          </Container>
        )}
      </Card.Body>
    </Card>
  );
};

export default ClientInformation;
