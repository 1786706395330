import { Form } from "react-bootstrap";

const InputFieldFormik = ({
  handleChange,
  value,
  placeholder,
  handleBlur,
  label,
  type,
  name,
  error,
  touched,
  disabled,
}) => {
  return (
    <Form.Group controlId={name} className="mb-3">
      <Form.Label className="mb-1">{label}</Form.Label>
      <Form.Control
        type={type}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        isInvalid={!(value && !error) && touched}
        isValid={value && !error}
        disabled={disabled}
      />
      <Form.Control.Feedback type="invalid">
        {error && touched ? <small>{error}</small> : null}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default InputFieldFormik;
