import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import { useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router";
import { destroy } from "utils/DeApi";

const DeleteOpportunity = ({ opportunityId, title }) => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const handleDelete = () => {
    setIsLoading(true);
    const clientPromise = destroy(`/opportunities/${opportunityId}`);

    clientPromise.promise
      .then((response) => {
        navigate("/");
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <Button
        variant="outline-danger"
        size="sm"
        onClick={() => {
          setShow(true);
        }}
      >
        Delete Opportunity
      </Button>
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Opportunity - {title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete the opportunity?
        </Modal.Body>
        <Modal.Footer>
          <Button
            size="sm"
            variant="secondary"
            onClick={() => {
              setShow(false);
            }}
          >
            Cancel
          </Button>
          <Button
            size="sm"
            variant="danger"
            disabled={isLoading}
            onClick={handleDelete}
          >
            {isLoading && (
              <Spinner
                className="me-2"
                animation="border"
                size="sm"
                variant="light"
              />
            )}
            Yes, I want to delete
          </Button>
        </Modal.Footer>
        {error && <ErrorHandler error={error} />}
      </Modal>
    </>
  );
};

export default DeleteOpportunity;
