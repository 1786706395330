import { useEffect, useRef } from "react";
import * as echarts from "echarts";
import { Card } from "react-bootstrap";

const CHART_HEIGHT = 270;

const RevenueChart = () => {
  const el = useRef(null);

  useEffect(() => {
    const option = {
      tooltip: {
        trigger: "item",
      },
      legend: {
        orient: "horizontal",
        icon: "circle",
        textStyle: {
          fontSize: 14,
        },
      },
      title: {
        show: true,
        textStyle: {
          color: "grey",
          fontSize: 15,
        },
        left: "center",
        top: "bottom",
      },
      series: [
        {
          name: "name",
          type: "pie",
          radius: "50%",
          label: {
            show: true,
            formatter: "{b}: {c}\n({d}%)",
          },
          percentPrecision: 1,
          data: [
            { name: "Equipment And Supplies", value: 5000 },
            { name: "Labor", value: 20000 },
            { name: "Digital Products", value: 25000 },
          ],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    };

    // echarts.registerTheme("wonderland", wonderland);

    const chart = echarts.init(el.current, null, {
      width: el.current.offsetWidth,
      height: CHART_HEIGHT,
    });
    chart.setOption(option);

    function handleResize() {
      chart.resize({
        width: el.current.offsetWidth,
        height: CHART_HEIGHT,
      });
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      chart.dispose();
    };
  }, [el]);

  return (
    <Card className="mt-3 mb-3">
      <Card.Body>
        <h6>Distribution of Revenue</h6>
        <hr />
        <div className="chart-container">
          <div className="w-100" ref={el} />
        </div>
      </Card.Body>
    </Card>
  );
};

export default RevenueChart;
