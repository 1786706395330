import { usePhases } from "contexts/PhasesProvider";
import { Alert, Card, Col, Container, Row } from "react-bootstrap";
import SinglePhasesAndActivitiesCostingChart from "./SinglePhasesAndActivitiesCostingChart";

const PhasesAndActivitiesCostingChart = () => {
  const phases = usePhases();

  return (
    <Card className="mt-3 mb-3">
      <Card.Body>
        <h6>Resources Costing - Distribution of Resources Costing</h6>
        <hr />

        {Array.isArray(phases) && phases.length === 0 ? (
          <Alert variant="info" className={`my-3 d-flex flex-row`}>
            <div className="me-3">
              <span className="material-icons md-18 text-primary text-opacity-75">
                info
              </span>
            </div>
            <div>
              <h5 className="mb-1">
                <small>No Phases</small>
              </h5>
              <p className="mb-1">
                <small>
                  There are currently no phases or activities to show
                  distribution of resources costing on
                </small>
              </p>
            </div>
          </Alert>
        ) : (
          <Container responsive size="sm">
            <Row>
              <Col xs={6}>
                <th>Phase</th>
              </Col>
              <Col className="d-flex justify-content-end">
                <th>Labour</th>
              </Col>
              <Col className="d-flex justify-content-end">
                <th>Equipment & Supplies</th>
              </Col>
              <Col className="d-flex justify-content-end">
                <th>Digital Products</th>
              </Col>
            </Row>
            {phases.map((phase, index) => {
              let { id } = phase;

              return (
                <>
                  <SinglePhasesAndActivitiesCostingChart
                    key={id}
                    phase={phase}
                    index={index}
                  />
                  <hr />
                </>
              );
            })}
          </Container>
        )}
      </Card.Body>
    </Card>
  );
};

export default PhasesAndActivitiesCostingChart;
