import { Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import {
  Modal,
  Button,
  Form,
  Spinner,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import * as yup from "yup";

import { useCallGetDeApi } from "hooks/useCallApi";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import InputFieldFormik from "components/Inputs/InputFieldFormik";
import DropdownFieldFormik from "components/Inputs/DropdownFieldFormik";
import DropdownField from "components/Inputs/DropdownField";
import InputField from "components/Inputs/InputField";
import { post } from "utils/DeApi";

const AddProductModal = ({ show, handleClose, activityId, updateData }) => {
  const subscribedPromises = useRef([]);
  const [equipmentCategoriesId, setEquipmentCategoriesId] = useState("");

  const [equipment, setEquipment] = useState();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const addEquipment = ({
    equipmentId,
    equipmentQuantity,
    equipmentTaxVat,
    equipmentTaxWht,
  }) => {
    setError(null);
    setIsLoading(true);

    const digitalEquipmentPromise = post(
      `/activities/${activityId}/digital-equipment`,
      {
        equipmentId,
        equipmentQuantity,
        equipmentTaxVat,
        equipmentTaxWht,
      },
    );

    digitalEquipmentPromise.promise
      .then((response) => {
        handleClose();
        updateData(response.data);
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });

    subscribedPromises.current.push(digitalEquipmentPromise);
  };

  const {
    data: digitalCategoriesData,
    error: digitalCategoriesError,
    isLoading: digitalCategoriesIsLoading,
  } = useCallGetDeApi("/digital-equipment-categories");

  const { data: equipmentData, isLoading: equipmentIsLoading } =
    useCallGetDeApi(`/equipment-categories/${equipmentCategoriesId}/equipment`);

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => promise.cancel());
    };
  }, []);

  const schema = yup.object({
    equipmentId: yup.string().required("Equiment is required"),
    equipmentQuantity: yup
      .number()
      .moreThan(0)
      .required("Quantity greater than 0 is required"),
    equipmentTaxVat: yup.number(),
    equipmentTaxWht: yup.number(),
  });

  const initialValues = {
    equipmentId: "",
    equipmentQuantity: 1,
    equipmentTaxVat: 0,
    equipmentTaxWht: 0,
  };

  return (
    <Modal show={show} onHide={handleClose} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Add Digital Product</Modal.Title>
      </Modal.Header>
      <Formik
        validationSchema={schema}
        onSubmit={(values) => {
          addEquipment(values);
        }}
        initialValues={initialValues}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
        }) => {
          setEquipment(
            Array.isArray(equipmentData) &&
              equipmentData.find((item) => {
                return item.id === values.equipmentId;
              }),
          );

          return (
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                <DropdownField
                  data={digitalCategoriesData}
                  textKey="title"
                  label={"Digital Product Category"}
                  placeholder={"Select Digital Product Category"}
                  isLoading={digitalCategoriesIsLoading}
                  handleChange={setEquipmentCategoriesId}
                />
                {equipmentCategoriesId && (
                  <>
                    <DropdownFieldFormik
                      data={equipmentData}
                      textKey="title"
                      name="equipmentId"
                      label={"Digital Product"}
                      error={errors.equipmentId}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      placeholder={"Select Equipment"}
                      touched={touched.equipmentId}
                      value={values.equipmentId}
                      isLoading={equipmentIsLoading}
                    />
                    {values.equipmentId && (
                      <>
                        <Container>
                          <Row>
                            <Col>
                              <InputField
                                layout="row"
                                label="Currency Unit"
                                disabled={true}
                                value={equipment?.currencyUnit}
                              />
                            </Col>
                            <Col>
                              <InputField
                                layout="row"
                                label="Local Cost"
                                disabled={true}
                                value={equipment?.localCost}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <InputField
                                layout="row"
                                label="Rate Unit"
                                disabled={true}
                                value={equipment?.rateUnit}
                              />
                            </Col>
                            <Col>
                              <InputField
                                layout="row"
                                label="Reimbursible"
                                disabled={true}
                                value={
                                  equipment?.reimbursible === 1 ? "Yes" : "No"
                                }
                              />
                            </Col>
                          </Row>
                        </Container>
                        <InputFieldFormik
                          name="equipmentQuantity"
                          label={"Equipment Quantity"}
                          type="number"
                          error={errors.equipmentQuantity}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          placeholder={"0"}
                          touched={touched.equipmentQuantity}
                          value={values.equipmentQuantity}
                        />
                      </>
                    )}
                  </>
                )}
                {error && <ErrorHandler error={error} />}
                {digitalCategoriesError && (
                  <ErrorHandler error={digitalCategoriesError} />
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size="sm"
                  variant="secondary"
                  className="bg-secondary bg-opacity-25 text-secondary border-0 fw-medium"
                  onClick={handleClose}
                >
                  Cancel
                </Button>

                <Button
                  type="submit"
                  size="sm"
                  id="digitalId"
                  disabled={
                    Object.keys(errors).length > 0 || isLoading || !isValid
                  }
                >
                  {isLoading && (
                    <Spinner
                      className="me-2"
                      animation="border"
                      size="sm"
                      variant="light"
                    />
                  )}{" "}
                  Add Digital Product
                </Button>
              </Modal.Footer>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};
export default AddProductModal;
