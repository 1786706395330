import { Form, InputGroup } from "react-bootstrap";

const InputField = ({
  handleChange,
  value,
  placeholder,
  label,
  type,
  name,
  layout,
  onBlur,
  disabled,
  className,
}) => {
  if (layout === "row") {
    return (
      <InputGroup controlId={name} className={"mb-3 " + [className]}>
        <InputGroup.Text>{label}</InputGroup.Text>
        <Form.Control
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={(e) => {
            handleChange(e.target.value);
          }}
          onBlur={onBlur}
          disabled={disabled}
        />
      </InputGroup>
    );
  }
  return (
    <Form.Group controlId={name} className={"mb-3 " + [className]}>
      <Form.Label className="mb-1">{label}</Form.Label>
      <Form.Control
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={(e) => {
          handleChange(e.target.value);
        }}
        onBlur={onBlur}
        disabled={disabled}
      />
    </Form.Group>
  );
};

export default InputField;
