import React from "react";
import { Table } from "react-bootstrap";

import { useSearchParams } from "react-router-dom";

import { usePhases } from "contexts/PhasesProvider";
import CostingTablePhase from "./CostingTablePhase";
import "./CostingTable.scss";

import CostingTableEquipmentHeader from "./CostingTableHeaders/CostingTableEquipmentHeader";
import CostingTableLabourHeader from "./CostingTableHeaders/CostingTableLabourHeader";
import DigitalEquipmentHeader from "./CostingTableHeaders/DigitalEquipmentHeader";

const CostingTable = () => {
  const phasesData = usePhases();

  const [searchParams] = useSearchParams();

  const costingType = searchParams.get("type");
  return (
    <Table responsive id="costing-grid">
      <div className="p-0">
        {costingType === "labour" && <CostingTableLabourHeader />}
        {costingType === "equipmentAndSupplies" && (
          <CostingTableEquipmentHeader />
        )}
        {costingType === "digitalProduct" && <DigitalEquipmentHeader />}
      </div>

      {phasesData.map((phase, index) => (
        <CostingTablePhase
          key={phase.id}
          phase={phase}
          phaseIndex={index}
          costingType={costingType}
        />
      ))}
    </Table>
  );
};

export default CostingTable;
