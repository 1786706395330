import { useState } from "react";
import { Button } from "react-bootstrap";

import AddEquipmentAndSuppliesModal from "./AddEquipmentAndSuppliesModal";

const AddEquipmentAndSupplies = ({ activityId, updateData }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => {
    setShow(true);
  };

  return (
    <>
      <Button
        size="sm"
        variant="outline-success"
        className="py-0 px-3"
        onClick={handleShow}
      >
        Add Equipment & Supplies
      </Button>
      {show && (
        <AddEquipmentAndSuppliesModal
          activityId={activityId}
          show={show}
          handleClose={handleClose}
          updateData={updateData}
        />
      )}
    </>
  );
};

export default AddEquipmentAndSupplies;
