import { useCallGetDeApi } from "hooks/useCallApi";
import { useEffect, useState } from "react";
import { Accordion, Alert, Card } from "react-bootstrap";
import AddPhase from "./AddPhase";
import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import SinglePhase from "./SinglePhase";

const Phases = ({ opportunityId }) => {
  const { data, isLoading, error, callApi } = useCallGetDeApi(`/phases`, {
    params: {
      opportunityId: opportunityId,
    },
  });

  const [phasesData, setPhasesData] = useState();

  useEffect(() => {
    setPhasesData(data);
  }, [data]);

  const updatePhasesData = (newData) => {
    setPhasesData([...phasesData, newData]);
  };

  const refreshPhasesData = () => {
    callApi();
  };

  return (
    <Card>
      <Card.Body>
        <div className="d-flex float-end">
          <AddPhase
            opportunityId={opportunityId}
            updatePhasesData={updatePhasesData}
          />
        </div>
        <h4>Phases and Activities</h4>
        <hr />

        {isLoading ? (
          <Loader />
        ) : error ? (
          <ErrorHandler error={error} />
        ) : (
          <>
            {Array.isArray(phasesData) && phasesData.length === 0 ? (
              <Alert variant="info" className={`my-3 d-flex flex-row`}>
                <div className="me-3">
                  <span className="material-icons md-18 text-primary text-opacity-75">
                    info
                  </span>
                </div>
                <div>
                  <h5 className="mb-1">
                    <small>No Phases</small>
                  </h5>
                  <p className="mb-1">
                    <small>
                      There are currently no phases or activities to show.
                      Please add a new phase using the Add Phase button
                    </small>
                  </p>
                </div>
              </Alert>
            ) : (
              <Accordion defaultActiveKey="0" flush className="my-3" alwaysOpen>
                {phasesData?.map((phase, index) => {
                  const { id } = phase;

                  return (
                    <SinglePhase
                      key={id}
                      phase={phase}
                      index={index}
                      refreshList={refreshPhasesData}
                    />
                  );
                })}
              </Accordion>
            )}
          </>
        )}
      </Card.Body>
    </Card>
  );
};

export default Phases;
