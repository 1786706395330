import { useState } from "react";
import { useParams } from "react-router-dom";
import { get } from "utils/DeApi";
import { usePhasesDispatch } from "contexts/PhasesProvider";

export const useRefreshPhasesData = () => {
  const [isLoading, setIsLoading] = useState();

  let { opportunityId } = useParams();
  const setPhases = usePhasesDispatch();

  const callGetPhasesAPI = () => {
    setIsLoading(true);

    const getPhasesPromise = get(`/phases`, {
      params: {
        opportunityId: opportunityId,
      },
    });

    getPhasesPromise.promise
      .then((response) => {
        setPhases(response.data);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          // reload the whole page if we couldn't update the opportunities data
          window.location.reload();
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return { callGetPhasesAPI, isLoading };
};
