import React from "react";
import { useSearchParams } from "react-router-dom";
import { useFormik } from "formik";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

import {
  useDigitalEquipmentDispatch,
  useDigitalEquipmentCosting,
} from "contexts/CostingProvider";

import {
  EquipmentCategoryField,
  EquipmentSelectField,
  QuantityField,
} from "components/PhasesWithCosting/ActivityWithCosting/DigitalProduct/AddProduct/AddDigitalProductFields";

import { cellStyle, headingStyle, costingTypesMap } from "./common";

export default function DigitalEquipmentHeader() {
  const [searchParams] = useSearchParams();

  const newDigitalEquipmentCostingFormikBag = useFormik({
    initialValues: {
      equipmentCategoriesId: "",
      equipmentQuantity: "",
      employeeId: "",
    },
  });

  const costingType = costingTypesMap[searchParams.get("type")];
  const setEquipmentCosting = useDigitalEquipmentDispatch();
  const equipmentsData = useDigitalEquipmentCosting();

  return (
    <>
      <tr>
        <td className={headingStyle} xs={6}>
          Equipment Category
        </td>
        {equipmentsData.map((equipment) => {
          if (Object.keys(equipment).length === 1) {
            return (
              <td key={equipment.id} className={cellStyle} autoFocus={true}>
                <EquipmentCategoryField
                  handleBlur={newDigitalEquipmentCostingFormikBag.handleBlur}
                  handleChange={
                    newDigitalEquipmentCostingFormikBag.handleChange
                  }
                  fieldValue={
                    newDigitalEquipmentCostingFormikBag.values
                      .equipmentCategoriesId
                  }
                />
              </td>
            );
          }
          return (
            <td key={equipment.id} className={cellStyle}>
              {equipment.equipmentCategory?.title}
            </td>
          );
        })}
        <span className="px-0">
          <OverlayTrigger overlay={<Tooltip>Add {costingType}</Tooltip>}>
            <span className="btn">
              <span
                className="material-icons-outlined"
                onClick={() =>
                  setEquipmentCosting([
                    ...equipmentsData.filter((eq) => eq.id !== "new"),
                    { id: "new" },
                  ])
                }
              >
                add
              </span>
            </span>
          </OverlayTrigger>
        </span>
      </tr>
      <tr>
        <td className={headingStyle} xs={6}>
          Equipment Name
        </td>
        {equipmentsData.map((equipment) => {
          if (Object.keys(equipment).length === 1) {
            return (
              <td key={equipment.id} className={cellStyle}>
                <EquipmentSelectField
                  formikBag={newDigitalEquipmentCostingFormikBag}
                  setSelectedEquipment={setEquipmentCosting}
                  equipmentsData={equipmentsData}
                />
              </td>
            );
          }
          return (
            <td key={equipment.id} className={cellStyle}>
              {equipment.title}
            </td>
          );
        })}
      </tr>
      <tr>
        <td className={headingStyle} xs={6}>
          Description
        </td>
        {equipmentsData.map((equipment) => {
          if (Object.keys(equipment).length === 1) {
            return <td key={equipment.id} className={cellStyle}></td>;
          }
          return (
            <td key={equipment.id} className={cellStyle}>
              {equipment.description || "n/a"}
            </td>
          );
        })}
      </tr>
      <tr>
        <td className={headingStyle} xs={6}>
          Currency
        </td>
        {equipmentsData.map((equipment) => {
          if (Object.keys(equipment).length === 1) {
            return <td key={equipment.id} className={cellStyle}></td>;
          }
          return (
            <td key={equipment.id} className={cellStyle}>
              {equipment.currencyUnit}
            </td>
          );
        })}
      </tr>
      <tr>
        <td className={headingStyle} xs={6}>
          Local Cost
        </td>
        {equipmentsData.map((equipment) => {
          if (Object.keys(equipment).length === 1) {
            return <td key={equipment.id} className={cellStyle}></td>;
          }
          return (
            <td key={equipment.id} className={cellStyle}>
              {equipment.localCost}
            </td>
          );
        })}
      </tr>
      <tr>
        <td className={headingStyle} xs={6}>
          Tax -WHT
        </td>
        {equipmentsData.map((equipment) => {
          if (Object.keys(equipment).length === 1) {
            return <td key={equipment.id} className={cellStyle}></td>;
          }
          return (
            <td key={equipment.id} className={cellStyle}>
              N/A
            </td>
          );
        })}
      </tr>
    </>
  );
}
