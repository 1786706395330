import { useState } from "react";
import { useSearchParams } from "react-router-dom";

import {
  useLabourCosting,
  useEquipmentCosting,
  useDigitalEquipmentCosting,
} from "contexts/CostingProvider";
import LabourTableCell from "./CostingTableCell/Labour";
import EquipmentTableCell from "./CostingTableCell/Equipment";
import DigitalEquipmentTableCell from "./CostingTableCell/DigitalEquipment";
import "./CostingTable.scss";

/*
  the phase index and activity index is used directly to get the utilization of a labour
*/

const CostingTableActivity = ({ activity, phaseIndex, activityIndex }) => {
  const labourData = useLabourCosting();
  const equipmentData = useEquipmentCosting();
  const digitalEquipmentData = useDigitalEquipmentCosting();

  const [isHover, setIsHover] = useState(false);

  const [searchParams] = useSearchParams();

  let headingStyle =
    (isHover && "bg-secondary bg-opacity-10") +
    [" border bg-white cell-heading-width align-middle cell-padding"];

  return (
    <tr
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
      className={"" + [isHover && "bg-secondary bg-opacity-10"]}
    >
      <td className={headingStyle}>
        {`${phaseIndex + 1}.${activityIndex + 1} ${activity.title}`}
      </td>
      {searchParams.get("type") === "labour" &&
        labourData.map((labour) => {
          return (
            <LabourTableCell
              key={labour.id}
              activity={activity}
              labour={labour}
              activityIndex={activityIndex}
              phaseIndex={phaseIndex}
            />
          );
        })}
      {searchParams.get("type") === "equipmentAndSupplies" &&
        equipmentData.map((equipment) => {
          return (
            <EquipmentTableCell
              key={equipment.id}
              activity={activity}
              equipment={equipment}
              activityIndex={activityIndex}
              phaseIndex={phaseIndex}
            />
          );
        })}
      {searchParams.get("type") === "digitalProduct" &&
        digitalEquipmentData.map((equipment) => {
          return (
            <DigitalEquipmentTableCell
              key={equipment.id}
              activity={activity}
              equipment={equipment}
              activityIndex={activityIndex}
              phaseIndex={phaseIndex}
            />
          );
        })}
    </tr>
  );
};

export default CostingTableActivity;
