import SingleActivityWithEquipment from "./EquipmentAndSupplies/SingleActivityWithEquipment";
import SingleActivityWithLabour from "./Labour/SingleActivityWithLabour";
import { useOpportunityCostingDispatch } from "contexts/OpportunityCostingProvider";
import SingleActivityWithDigitalProduct from "./SingleActivityWithDigitalProduct";

const SingleActivityWithCosting = ({
  type,
  activity,
  phaseIndex,
  activityIndex,
  updatePhasesData,
}) => {
  const dispatch = useOpportunityCostingDispatch();

  const updateActivitiesData = (newData) => {
    dispatch({
      type: "addActivities",
      activity: newData,
    });
    updatePhasesData(phaseIndex, activityIndex, newData);
  };

  if (activity) {
    let { id } = activity;
    if (type === "equipmentAndSupplies") {
      return (
        <SingleActivityWithEquipment
          activityId={id}
          phaseIndex={phaseIndex}
          activityIndex={activityIndex}
          updatePhasesData={updatePhasesData}
          updateActivitiesData={updateActivitiesData}
          activity={activity}
        />
      );
    } else if (type === "labour") {
      return (
        <SingleActivityWithLabour
          activityId={id}
          phaseIndex={phaseIndex}
          activityIndex={activityIndex}
          updatePhasesData={updatePhasesData}
          updateActivitiesData={updateActivitiesData}
          activity={activity}
        />
      );
    } else if (type === "digitalProduct") {
      return (
        <SingleActivityWithDigitalProduct
          activityId={id}
          phaseIndex={phaseIndex}
          activityIndex={activityIndex}
          updatePhasesData={updatePhasesData}
          updateActivitiesData={updateActivitiesData}
          activity={activity}
        />
      );
    }
  }
};

export default SingleActivityWithCosting;
