import { Accordion, Alert, Table } from "react-bootstrap";

import SingleEquipment from "./SingleEquipment";
import AddEquipmentAndSupplies from "./AddEquipmentAndSupplies/AddEquipmentAndSupplies";

const SingleActivityWithEquipment = ({
  activityId,
  phaseIndex,
  activityIndex,
  activity,
  updateActivitiesData,
}) => {
  const { id, summary, title, equipment } = activity;

  return (
    <Accordion flush>
      <Accordion.Item eventKey={activityId} key={activityId}>
        <Accordion.Header>
          <div className="d-flex w-100 flex-row justify-content-between">
            <span>
              {phaseIndex + 1}.{activityIndex + 1}. {title}
            </span>

            <div className="me-2">
              <span>{summary.equipmentCount} Equipments</span>
              <span
                className={
                  [
                    summary.totalEquipmentCost > 0
                      ? "bg-success text-success"
                      : "bg-danger text-danger",
                  ] + " bg-opacity-25 px-3 ms-2"
                }
              >
                ${summary.totalEquipmentCost}
              </span>
            </div>
          </div>
        </Accordion.Header>
        <Accordion.Body className="border">
          <div>
            {Array.isArray(equipment) && equipment.length === 0 ? (
              <Alert variant="info" className={`d-flex flex-row`}>
                <div className="me-3">
                  <span className="material-icons md-18 text-primary text-opacity-75">
                    info
                  </span>
                </div>
                <div>
                  <h5 className="mb-1">
                    <small>No Equipments</small>
                  </h5>
                  <p className="mb-1">
                    <small>There are currently no equipments added.</small>
                  </p>
                </div>
              </Alert>
            ) : (
              <>
                <Table size="sm" responsive>
                  <thead>
                    <tr>
                      <th>Serial</th>
                      <th>Category</th>
                      <th>Name</th>
                      <th className="text-end">Per Unit Cost</th>
                      <th className="text-end">Quantity</th>
                      <th className="text-end">Total</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(equipment) &&
                      equipment?.map(
                        (singleEquipment, singleEquipmentIndex) => {
                          const { id } = singleEquipment;

                          return (
                            <SingleEquipment
                              equipment={singleEquipment}
                              key={id}
                              index={singleEquipmentIndex}
                              activityId={activityId}
                              updateData={updateActivitiesData}
                            />
                          );
                        },
                      )}
                  </tbody>
                </Table>
              </>
            )}
          </div>
          <div className="text-end">
            <AddEquipmentAndSupplies
              activityId={id}
              updateData={updateActivitiesData}
            />
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default SingleActivityWithEquipment;
