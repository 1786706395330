import { createContext, useContext, useReducer } from "react";

const initialOpportunityCosting = {
  summary: {
    equipmentAndSupplies: {
      totalCount: 0,
      totalCost: 0,
    },
    labour: {
      totalCount: 0,
      totalCost: 0,
    },
    digitalProduct: {
      totalCount: 0,
      totalCost: 0,
    },
  },
  activities: [],
};

const opportunityCostingContext = createContext(null);
const opportunityCostingDispatchContext = createContext(null);

export function OpportunityCostingProvider({ children }) {
  const [opportunityCosting, dispatch] = useReducer(
    opportunityCostingReducer,
    initialOpportunityCosting,
  );

  return (
    <opportunityCostingContext.Provider value={opportunityCosting}>
      <opportunityCostingDispatchContext.Provider value={dispatch}>
        {children}
      </opportunityCostingDispatchContext.Provider>
    </opportunityCostingContext.Provider>
  );
}

export function useOpportunityCostingEquipments() {
  return useContext(opportunityCostingContext);
}

export function useOpportunityCostingDispatch() {
  return useContext(opportunityCostingDispatchContext);
}

function opportunityCostingReducer(opportunityCosting, action) {
  switch (action.type) {
    case "addActivities": {
      let totalLabourCost = 0;
      let totalLabourCount = 0;
      let totalEquipmentAndSuppliesCost = 0;
      let totalEquipmentAndSuppliesCount = 0;
      let totalDigitalProductCount = 0;
      let totalDigitalProductCost = 0;

      let temp = [...opportunityCosting.activities];
      let isPresentIndex = temp.findIndex((element) => {
        return element.id === action.activity.id;
      });

      if (isPresentIndex === -1) {
        temp.push({
          id: action.activity.id,
          summary: action.activity.summary,
        });
      } else {
        temp[isPresentIndex] = {
          id: action.activity.id,
          summary: action.activity.summary,
        };
      }

      temp.forEach((element) => {
        // LABOUR
        // Get total cost
        totalLabourCost += element.summary.totalLaborCost;
        // Get total count
        totalLabourCount += element.summary.laborCount;
        // EQUIPMENT AND SUPPLIES
        // Get total cost
        totalEquipmentAndSuppliesCost += element.summary.totalEquipmentCost;
        // Get total count
        totalEquipmentAndSuppliesCount += element.summary.equipmentCount;
        // Digital Products
        // Get total cost
        totalDigitalProductCost += element.summary.totalDigitalEquipmentCost;
        // Get total count
        totalDigitalProductCount += element.summary.digitalEquipmentCount;
      });

      let tempSummary = {
        equipmentAndSupplies: {
          totalCount: totalEquipmentAndSuppliesCount,
          totalCost: totalEquipmentAndSuppliesCost,
        },
        labour: {
          totalCount: totalLabourCount,
          totalCost: totalLabourCost,
        },
        digitalProduct: {
          totalCount: totalDigitalProductCount,
          totalCost: totalDigitalProductCost,
        },
      };

      return {
        summary: tempSummary,
        activities: temp,
      };
    }
    default: {
      throw Error("Unknown action: " + action.type);
    }
  }
}
