import React, { useEffect } from "react";
import * as echarts from "echarts/lib/echarts.js";

const LabourPieChart = ({ careerLevelSummary, totalLaborHours }) => {
  useEffect(() => {
    const filteredData = careerLevelSummary
      .filter((level) => level.hoursPercentage > 0) // Filter out data with 0 value
      .map((level) => {
        return {
          value: (level.hoursPercentage / totalLaborHours) * 100,
          name: level.title,
        };
      });
    const chart = echarts.init(document.getElementById("pie-chart"));

    const option = {
      tooltip: {
        trigger: "item",
        formatter: function (params) {
          const formattedValue = parseFloat(params.value).toFixed(2);
          return params.name + ": " + formattedValue + "%";
        },
      },
      series: [
        {
          type: "pie", // Use pie chart type
          radius: "50%", // Set radius to control the size
          center: ["50%", "50%"], // Center the pie chart in the container
          label: {
            show: true,
            position: "inside",
          },
          emphasis: {
            label: {
              fontSize: 20,
            },
          },
          data: filteredData,
        },
      ],
    };

    chart.setOption(option);

    return () => {
      chart.dispose();
    };
  }, [careerLevelSummary]);

  return <div id="pie-chart" style={{ width: "100%", height: "500px" }} />;
};

export default LabourPieChart;
