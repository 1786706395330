import { Accordion, Alert } from "react-bootstrap";
import PropTypes from "prop-types";

import SingleActivityWithCosting from "./ActivityWithCosting/SingleActivityWithCosting";

const SinglePhaseWithCosting = ({ phase, index, updatePhasesData, type }) => {
  const { id, title, activities, summary } = phase;

  return (
    <Accordion className="border-bottom border-dark border-opacity-25">
      <Accordion.Item eventKey={index} key={id}>
        <Accordion.Header>
          <div className="d-flex w-100 flex-row justify-content-between">
            <span className="fs-5">
              {index + 1}. {title}
            </span>
            <div className="me-2">
              <span className="fs-5">
                {type === "equipmentAndSupplies" && (
                  <>{summary.totalEquipmentCount} Equipments</>
                )}
                {type === "labour" && <>{summary.totalLaborCount} Labours</>}
                {type === "digitalProduct" && (
                  <>{summary.totalDigitalEquipmentCount} Digital Products</>
                )}
              </span>
              <>
                {type === "equipmentAndSupplies" && (
                  <big
                    className={
                      [
                        summary.totalEquipmentCost > 0
                          ? "bg-success text-success"
                          : "bg-danger text-danger",
                      ] + " bg-opacity-25 px-3 ms-3"
                    }
                  >
                    ${summary.totalEquipmentCost}
                  </big>
                )}
                {type === "labour" && (
                  <big
                    className={
                      [
                        summary.totalLaborCost > 0
                          ? "bg-success text-success"
                          : "bg-danger text-danger",
                      ] + " bg-opacity-25 px-3 ms-3"
                    }
                  >
                    ${summary.totalLaborCost}
                  </big>
                )}
                {type === "digitalProduct" && (
                  <big
                    className={
                      [
                        summary.totalDigitalEquipmentCost > 0
                          ? "bg-success text-success"
                          : "bg-danger text-danger",
                      ] + " bg-opacity-25 px-3 ms-3"
                    }
                  >
                    ${summary.totalDigitalEquipmentCost}
                  </big>
                )}
              </>
            </div>
          </div>
        </Accordion.Header>
        <Accordion.Body className="border">
          {Array.isArray(activities) && activities.length === 0 ? (
            <Alert variant="info" className={`d-flex flex-row`}>
              <div className="me-3">
                <span className="material-icons md-18 text-primary text-opacity-75">
                  info
                </span>
              </div>
              <div>
                <h5 className="mb-1">
                  <small>No Activities</small>
                </h5>
                <p className="mb-1">
                  <small>There are currently no activities to show.</small>
                </p>
              </div>
            </Alert>
          ) : (
            <>
              {Array.isArray(activities) &&
                activities?.map((activity, activityIndex) => {
                  const { id } = activity;

                  return (
                    <SingleActivityWithCosting
                      key={id}
                      activity={activity}
                      activityIndex={activityIndex}
                      phaseIndex={index}
                      type={type}
                      updatePhasesData={updatePhasesData}
                    />
                  );
                })}
            </>
          )}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

SinglePhaseWithCosting.propTypes = {
  type: PropTypes.oneOf(["equipmentAndSupplies", "labour", "digitalProduct"]),
};

export default SinglePhaseWithCosting;
