import React, { useContext } from "react";
import "./Header.scss";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import { Helmet } from "react-helmet";

import Feedback from "../Feedback/Feedback";
import { UserContext } from "contexts/UserProvider";

const Header = ({ title }) => {
  const user = useContext(UserContext);

  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title || ""}</title>
      </Helmet>
      <Navbar
        bg="white"
        variant="light"
        expand="lg"
        fixed="top"
        className="border-bottom MainHeader"
      >
        <Container fluid>
          <Navbar.Brand as={Link} to="/">
            <span className="pe-2">
              <img
                className="img-responsive"
                src="/logo192.png"
                width="144px"
                alt="Logo"
              />{" "}
            </span>
            Value Navigator
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse>
            <Nav className="ms-auto">
              <NavDropdown
                align="end"
                className="me-3"
                title={`Action Items`}
              ></NavDropdown>{" "}
              <Feedback />
              <NavDropdown align="end" title={`Hi, ${user?.firstName}`}>
                <NavDropdown.Item disabled>{user?.email}</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item as={Link} to="/logout">
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </React.Fragment>
  );
};

Header.propTypes = {
  title: PropTypes.string,
};

export default Header;
