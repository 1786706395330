import React, { useEffect, useRef } from "react";
import "./OpportunitiesChart.scss";
import * as echarts from "echarts";

const OpportunitiesChart = () => {
  const el = useRef(null);

  useEffect(() => {
    const option = {
      tooltip: {
        trigger: "axis",
      },
      legend: {
        data: ["Labor", "Labs", "Equipment", "Expenses", "Subcontractors"],
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      toolbox: {
        feature: {
          saveAsImage: {},
        },
      },
      xAxis: {
        type: "category",
        boundaryGap: false,
        data: ["Jan", "Feb", "March", "April", "May", "June", "July"],
      },
      yAxis: {
        type: "value",
      },
      series: [
        {
          name: "Labor",
          type: "line",
          stack: "Total",
          data: [12, 13, 10, 13, 90, 23, 21],
        },
        {
          name: "Labs",
          type: "line",
          stack: "Total",
          data: [22, 18, 19, 234, 290, 33, 31],
        },
        {
          name: "Expenses",
          type: "line",
          stack: "Total",
          data: [15, 23, 20, 15, 19, 33, 41],
        },
        {
          name: "Equipment",
          type: "line",
          stack: "Total",
          data: [32, 33, 30, 33, 39, 33, 32],
        },
        {
          name: "Subcontractors",
          type: "line",
          stack: "Total",
          data: [82, 93, 90, 93, 129, 133, 132],
        },
      ],
    };

    const chart = echarts.init(el.current);
    chart.setOption(option);
  }, [el]);

  return (
    <div className="OpportunitiesChart">
      <div className="approved-opps-chart-container me-auto" ref={el} />
    </div>
  );
};

OpportunitiesChart.propTypes = {};

export default OpportunitiesChart;
