import { useContext } from "react";
import PhasesWithCosting from "components/PhasesWithCosting/PhasesWithCosting";
import { OpportunityContext } from "contexts/OpportunityProvider";
import { BreadcrumbDispatchContext } from "contexts/OpportunityProvider";
import { ActiveTabDispatchContext } from "contexts/OpportunityProvider";

const OpportunityCosting = () => {
  const opportunity = useContext(OpportunityContext);
  const setBreadcrumbs = useContext(BreadcrumbDispatchContext);
  const setActiveTab = useContext(ActiveTabDispatchContext);

  if (opportunity) {
    const { title, id } = opportunity;

    setBreadcrumbs([
      { name: "Dashboard", link: "/" },
      { name: "Opportunities", active: false },
      { name: title, link: `/opportunities/${id}` },
      { name: "Costing", active: false },
    ]);
    setActiveTab("costing");

    return (
      <div>
        <PhasesWithCosting opportunityId={id} />
      </div>
    );
  }
};

export default OpportunityCosting;
