import { useEffect, useState } from "react";
import { get, post } from "../utils/DeApi";

const useCallPostDeApi = (url, onSuccess) => {
  const [data, setData] = useState(null);
  const [body, setBody] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const callApi = () => {
    setIsLoading(true);
    setData(null);
    setError(null);

    const auditPromise = post(url, body);
    auditPromise.promise
      .then((response) => {
        setIsLoading(false);
        setData(response.data);
        onSuccess();
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
        setIsLoading(false);
      });
  };

  //   useEffect(()=>{
  //     return
  //   },[])

  return {
    setBody,
    data,
    isLoading,
    error,
    callApi,
  };
};

const useCallGetDeApi = (url, body) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [callAgain, setCallAgain] = useState(false);

  const callApi = () => {
    setCallAgain(!callAgain);
  };

  useEffect(() => {
    setIsLoading(true);
    setData(null);
    setError(null);

    const auditPromise = get(url, body);
    auditPromise.promise
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        // if(error.status === 500){
        //     naviga
        // }
        !error.isCanceled && setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });

    return () => {
      auditPromise.cancel();
    };
  }, [url, callAgain]);

  return {
    data,
    isLoading,
    error,
    callApi,
  };
};

export { useCallPostDeApi, useCallGetDeApi };
