import { useContext } from "react";
import { OpportunityContext } from "contexts/OpportunityProvider";
import { BreadcrumbDispatchContext } from "contexts/OpportunityProvider";
import { ActiveTabDispatchContext } from "contexts/OpportunityProvider";
import Phases from "components/Phases/Phases";

const OpportunityWBS = () => {
  const opportunity = useContext(OpportunityContext);
  const setBreadcrumbs = useContext(BreadcrumbDispatchContext);
  const setActiveTab = useContext(ActiveTabDispatchContext);

  if (opportunity) {
    const { id, title } = opportunity;
    setBreadcrumbs([
      { name: "Dashboard", link: "/" },
      { name: "Opportunities", active: false },
      { name: title, link: `/opportunities/${id}` },
      { name: "Work Breakdown", active: false },
    ]);
    setActiveTab("wbs");

    return (
      <>
        <Phases opportunityId={id} />
      </>
    );
  }
};

export default OpportunityWBS;
