import React from "react";
import { useCallGetDeApi } from "hooks/useCallApi";
import DropdownFieldFormik from "components/Inputs/DropdownFieldFormik";
import InputFieldFormik from "components/Inputs/InputFieldFormik";

export const WorkingOrgField = React.memo(
  ({ handleBlur, handleChange, fieldValue }) => {
    const {
      data: countriesData,
      error: countriesError,
      isLoading: countriesIsLoading,
    } = useCallGetDeApi("/countries");

    return (
      <DropdownFieldFormik
        data={countriesData}
        textKey="name"
        name="countryId"
        placeholder={"Select Working Organization"}
        isLoading={countriesIsLoading}
        handleChange={handleChange}
        handleBlur={handleBlur}
        value={fieldValue}
      />
    );
  },
);

export function EmployeeSelectField({
  formikBag,
  setSelectedLabour,
  labourData,
}) {
  const { data: employeesData, isLoading: employeesIsLoading } =
    useCallGetDeApi(`/employees?countryId=${formikBag.values.countryId}`);
  return (
    <DropdownFieldFormik
      data={employeesData}
      textKey="name"
      name="employeeId"
      error={formikBag.errors.employeeId}
      handleBlur={formikBag.handleBlur}
      handleChange={(e) => {
        setSelectedLabour([
          ...labourData.filter((lb) => lb.id !== "new"),
          {
            phases: [],
            ...employeesData?.find((emp) => emp.id === e.target.value),
          },
        ]);
        formikBag.handleChange(e);
        formikBag.resetForm(e);
      }}
      placeholder={"Select Employee"}
      touched={formikBag.touched.employeeId}
      value={formikBag.values.employeeId}
      isLoading={employeesIsLoading}
    />
  );
}

export function RateDiscountField({ formikBag }) {
  return (
    <InputFieldFormik
      name="rateDiscount"
      type="number"
      error={formikBag.errors.rateDiscount}
      handleBlur={formikBag.handleBlur}
      handleChange={formikBag.handleChange}
      placeholder={"0"}
      touched={formikBag.touched.rateDiscount}
      value={formikBag.values.rateDiscount}
      disabled
    />
  );
}

// export function QuantityField() {
//   return (
//     <InputFieldFormik
//       name="totalHours"
//       label={"Total Hours"}
//       type="number"
//       error={formikBag.errors.totalHours}
//       handleBlur={formikBag.handleBlur}
//       handleChange={formikBag.handleChange}
//       placeholder={"0"}
//       touched={formikBag.touched.totalHours}
//       value={formikBag.values.totalHours}
//     />
//   );
// }
