import { useRef, useState, useEffect } from "react";
import { Badge, Button, Form, Spinner } from "react-bootstrap";

import { useRefreshOpportunityData } from "helpers/OpportunityHelpers";
import { useRefreshPhasesData } from "helpers/PhasesHelpers";
import "../CostingTable.scss";

import { destroy, put, post } from "utils/DeApi";

const DigitalEquipmentTableCell = ({
  equipment,
  phaseIndex,
  activityIndex,
  activity,
}) => {
  const [isEquipmentForActivity, setIsEquipmentForActivity] = useState(
    !!activity.digitalEquipment?.find((eq) => eq.id === equipment.id),
  );

  const [totalHours, setTotalHours] = useState(
    equipment.phases?.[phaseIndex]?.activities[activityIndex].totalHours,
  );

  useEffect(() => {
    setTotalHours(
      equipment.phases?.[phaseIndex]?.activities[activityIndex].totalHours,
    );
  }, [equipment, phaseIndex, activityIndex]);

  useEffect(() => {
    setIsEquipmentForActivity(
      !!activity.digitalEquipment?.find((eq) => eq.id === equipment.id),
    );
  }, [activity, equipment]);

  const { callApi: callRefreshOpportunityDataApi } =
    useRefreshOpportunityData();
  const { callGetPhasesAPI } = useRefreshPhasesData();

  const cellStyle = "border cell-width align-middle cell-padding text-end";
  const hoursStyle = [
    "bg-warning text-warning",
    "bg-primary text-primary",
    "bg-success text-success",
  ];

  const [isHover, setIsHover] = useState(false);
  const subscribedPromises = useRef([]);
  const [isEditable, setIsEditable] = useState(false);
  const [equipmentChangeError, setEquipmentChangeError] = useState();
  const [equipmentChangeIsLoading, setEquipmentChangeIsLoading] =
    useState(false);
  const [equipmentDeleteError, setLabourDeleteError] = useState();
  const [labourDeleteIsLoading, setLabourDeleteIsLoading] = useState(false);
  const [newTotalHours, setNewTotalHours] = useState(totalHours);

  const handleLabourDeleteSubmit = () => {
    setLabourDeleteIsLoading(true);
    const labourDeletePromise = destroy(
      `/activities/${activity.id}/digital-equipment/${equipment.id}`,
    );

    labourDeletePromise.promise
      .then((_) => {
        callRefreshOpportunityDataApi();
        callGetPhasesAPI();
        setLabourDeleteError(null);
        setIsEditable(false);
        setNewTotalHours(0);
        setTotalHours(0);
      })
      .catch((error) => {
        !error.isCanceled && setLabourDeleteError(error);
      })
      .finally(() => {
        setLabourDeleteIsLoading(false);
      });

    subscribedPromises.current.push(labourDeletePromise);
  };

  const handleLabourChangeSubmit = () => {
    setEquipmentChangeIsLoading(true);

    const labourChangePromise = isEquipmentForActivity
      ? put(`/activities/${activity.id}/digital-equipment/${equipment.id}`, {
          equipmentQuantity: newTotalHours,
        })
      : post(`/activities/${activity.id}/digital-equipment`, {
          equipmentId: equipment.id,
          equipmentQuantity: newTotalHours,
        });

    labourChangePromise.promise
      .then((_) => {
        callRefreshOpportunityDataApi();
        callGetPhasesAPI();
        setEquipmentChangeError(null);
        setTotalHours(newTotalHours);
        setIsEditable(false);
      })
      .catch((error) => {
        !error.isCanceled && setEquipmentChangeError(error);
        console.log(error);
      })
      .finally(() => {
        setEquipmentChangeIsLoading(false);
      });

    subscribedPromises.current.push(labourChangePromise);
  };

  return (
    <td
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
      className={cellStyle}
    >
      <span className="d-flex w-100 justify-content-end align-middle my-auto">
        {(isHover || isEditable) && (
          <div className="me-auto align-middle">
            <Button
              size="sm"
              className="py-0 px-1"
              variant="outline-danger"
              onClick={handleLabourDeleteSubmit}
            >
              {labourDeleteIsLoading ? (
                <Spinner animation="border" size="sm" />
              ) : (
                <span className="material-icons-outlined md-18">
                  highlight_off
                </span>
              )}
            </Button>
            {isEditable && (
              <Button
                size="sm"
                className="py-0 px-1"
                variant="outline-success"
                onClick={handleLabourChangeSubmit}
              >
                {equipmentChangeIsLoading ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  <span className="material-icons-outlined md-18">done</span>
                )}
              </Button>
            )}
          </div>
        )}
        {isEditable ? (
          <Form.Control
            className="w-50"
            min={0}
            type="number"
            size="sm"
            value={newTotalHours}
            onChange={(e) => {
              setNewTotalHours(e.target.value);
            }}
          />
        ) : (
          <Badge
            onClick={() => {
              setIsEditable(true);
            }}
            className={
              [hoursStyle[totalHours === 0 ? 0 : totalHours <= 25 ? 1 : 2]] +
              " rounded-0 py-1 px-2 bg-opacity-10 text-end"
            }
          >
            <h6 className="mb-0">{totalHours}</h6>
          </Badge>
        )}
      </span>
    </td>
  );
};
export default DigitalEquipmentTableCell;
