import { createContext, useContext, useEffect, useState } from "react";
import { usePhases } from "./PhasesProvider";

const LabourCostingContext = createContext(undefined);
const LabourCostingDispatchContext = createContext(undefined);

const EquipmentCostingContext = createContext(undefined);
const EquipmentCostingDispatchContext = createContext(undefined);

const DigitalEquipmentCostingContext = createContext(undefined);
const DigitalEquipmentCostingDispatchContext = createContext(undefined);

function CostingProvider({ children }) {
  const phases = usePhases();

  const [labourCosting, setLabourCosting] = useState([]);
  const [equipmentCosting, setEquipmentCosting] = useState([]);
  const [digitalEquipmentCosting, setDigitalEquipmentCosting] = useState([]);

  useEffect(() => {
    organizeCostingIntoTabular(phases);
  }, [phases]);

  /*
    formats phases data into labour iterable data. Each index of the array is a unique labour with its details
    and inside each labour is a phases array with an activites array inside. Each phase and is present in the phases 
    and activities array regardless if the labour is being utilized in the activity or not (this is so that it is easily 
    mappable in a table view because we can directly use phase index and activity index to get the utilizztion in a table).
    If the opportunity has 2 phases with 3 activities inside each phase, then each labour will have phases with length 2 and 
    the activities length inside will be 3. If a labour is being utlizied in that activity, the total hours will be given,
    otherwise it will be 0
  */
  const organizeCostingIntoTabular = (phases) => {
    // this array has all the unique labours in the opportunity
    let initialLabourArray = [];
    let initialEquipments = [];
    let initialDigitalEquipments = [];

    phases.forEach((phase) => {
      phase.activities.forEach((activity) => {
        activity.labor.forEach((singleLabour) => {
          const isLabourPresentIndex = initialLabourArray.findIndex(
            (element) => {
              return element.id === singleLabour.id;
              // eslint-disable-next-line prettier/prettier
            }
          );

          if (isLabourPresentIndex === -1) {
            initialLabourArray.push({ ...singleLabour });
          }
        });

        activity.equipment.forEach((equipment) => {
          const isLabourPresentIndex = initialEquipments.findIndex(
            (element) => {
              return element.id === equipment.id;
              // eslint-disable-next-line prettier/prettier
            }
          );

          if (isLabourPresentIndex === -1) {
            initialEquipments.push({ ...equipment });
          }
        });

        activity.digitalEquipment.forEach((equipment) => {
          const isLabourPresentIndex = initialDigitalEquipments.findIndex(
            (element) => {
              return element.id === equipment.id;
              // eslint-disable-next-line prettier/prettier
            }
          );

          if (isLabourPresentIndex === -1) {
            initialDigitalEquipments.push({ ...equipment });
          }
        });
      });
    });

    let finalLabourArray = [];
    let finalEquipments = [];
    let finalDigitalEquipments = [];

    initialLabourArray.forEach((element) => {
      let singleItem = {
        ...element,
        phases: Array(phases.length).fill({}),
      };

      phases.forEach((phase, phaseIndex) => {
        let sumOfTotalHours = 0;

        singleItem.phases[phaseIndex] = {
          id: phase.id,
          title: phase.title,
          totalHours: 0,
          activities: Array(phase.activities.length).fill({}),
        };
        phase.activities.forEach((activity, activityIndex) => {
          const labourFound = activity.labor.find((labour) => {
            return element.id === labour.id;
          });
          if (labourFound) {
            sumOfTotalHours += labourFound.totalHours;
            singleItem.phases[phaseIndex].activities[activityIndex] = {
              id: activity.id,
              title: activity.title,
              total: labourFound.total,
              totalHours: labourFound.totalHours,
            };
          } else {
            singleItem.phases[phaseIndex].activities[activityIndex] = {
              id: activity.id,
              title: activity.title,
              total: 0,
              totalHours: 0,
            };
          }
        });
        singleItem.phases[phaseIndex].totalHours = sumOfTotalHours;
      });
      finalLabourArray.push(singleItem);
    });

    initialEquipments.forEach((element) => {
      let singleItem = {
        ...element,
        phases: Array(phases.length).fill({}),
      };

      phases.forEach((phase, phaseIndex) => {
        let sumOfTotalHours = 0;

        singleItem.phases[phaseIndex] = {
          id: phase.id,
          title: phase.title,
          totalHours: 0,
          activities: Array(phase.activities.length).fill({}),
        };
        phase.activities.forEach((activity, activityIndex) => {
          const labourFound = activity.equipment.find((labour) => {
            return element.id === labour.id;
          });
          if (labourFound) {
            sumOfTotalHours += labourFound.quantity;
            singleItem.phases[phaseIndex].activities[activityIndex] = {
              id: activity.id,
              title: activity.title,
              total: labourFound.total,
              totalHours: labourFound.quantity,
            };
          } else {
            singleItem.phases[phaseIndex].activities[activityIndex] = {
              id: activity.id,
              title: activity.title,
              total: 0,
              totalHours: 0,
            };
          }
        });
        singleItem.phases[phaseIndex].totalHours = sumOfTotalHours;
      });
      finalEquipments.push(singleItem);
    });

    initialDigitalEquipments.forEach((element) => {
      let singleItem = {
        ...element,
        phases: Array(phases.length).fill({}),
      };

      phases.forEach((phase, phaseIndex) => {
        let sumOfTotalHours = 0;

        singleItem.phases[phaseIndex] = {
          id: phase.id,
          title: phase.title,
          totalHours: 0,
          activities: Array(phase.activities.length).fill({}),
        };
        phase.activities.forEach((activity, activityIndex) => {
          const labourFound = activity.digitalEquipment.find((labour) => {
            return element.id === labour.id;
          });
          if (labourFound) {
            sumOfTotalHours += labourFound.quantity;
            singleItem.phases[phaseIndex].activities[activityIndex] = {
              id: activity.id,
              title: activity.title,
              total: labourFound.total,
              totalHours: labourFound.quantity,
            };
          } else {
            singleItem.phases[phaseIndex].activities[activityIndex] = {
              id: activity.id,
              title: activity.title,
              total: 0,
              totalHours: 0,
            };
          }
        });
        singleItem.phases[phaseIndex].totalHours = sumOfTotalHours;
      });
      finalDigitalEquipments.push(singleItem);
    });

    setLabourCosting(finalLabourArray);
    setEquipmentCosting(finalEquipments);
    setDigitalEquipmentCosting(finalDigitalEquipments);
  };

  return (
    <LabourCostingContext.Provider value={labourCosting}>
      <LabourCostingDispatchContext.Provider value={setLabourCosting}>
        <EquipmentCostingContext.Provider value={equipmentCosting}>
          <EquipmentCostingDispatchContext.Provider value={setEquipmentCosting}>
            <DigitalEquipmentCostingContext.Provider
              value={digitalEquipmentCosting}
            >
              <DigitalEquipmentCostingDispatchContext.Provider
                value={setDigitalEquipmentCosting}
              >
                {children}
              </DigitalEquipmentCostingDispatchContext.Provider>
            </DigitalEquipmentCostingContext.Provider>
          </EquipmentCostingDispatchContext.Provider>
        </EquipmentCostingContext.Provider>
      </LabourCostingDispatchContext.Provider>
    </LabourCostingContext.Provider>
  );
}

export function useLabourCosting() {
  return useContext(LabourCostingContext);
}

export function useEquipmentCosting() {
  return useContext(EquipmentCostingContext);
}

export function useDigitalEquipmentCosting() {
  return useContext(DigitalEquipmentCostingContext);
}

// generic function for retrieving consting data based on  costing type
export function useCosting(costingType = "labour") {
  const labourData = useLabourCosting();
  const equipmentData = useEquipmentCosting();
  const digitalEquipmentData = useDigitalEquipmentCosting();
  if (costingType === "labour") return labourData;
  else if (costingType === "equipmentAndSupplies") return equipmentData;
  else if (costingType === "digitalProduct") return digitalEquipmentData;
  else return labourData;
}

// generic function for retrieving consting dispatch based on  costing type
export function useCostingDispatch(costingType = "labour") {
  const labourDispatch = useLabourDispatch();
  const equipmentDispatch = useEquipmentDispatch();
  const digitalEquipmentDispatch = useDigitalEquipmentDispatch();
  if (costingType === "labour") return labourDispatch;
  else if (costingType === "equipmentAndSupplies") return equipmentDispatch;
  else if (costingType === "digitalProduct") return digitalEquipmentDispatch;
  else return labourDispatch;
}

export function useLabourDispatch() {
  return useContext(LabourCostingDispatchContext);
}

export function useEquipmentDispatch() {
  return useContext(EquipmentCostingDispatchContext);
}

export function useDigitalEquipmentDispatch() {
  return useContext(DigitalEquipmentCostingDispatchContext);
}

export { CostingProvider, LabourCostingContext, LabourCostingDispatchContext };
