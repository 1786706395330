import { useRef, useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";

import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import { useRefreshOpportunityData } from "helpers/OpportunityHelpers";
import { put, destroy } from "utils/DeApi";

const SingleLabour = ({ labour, index, activityId, updateData }) => {
  // To refresh the opportunity details api data to get updated values for the pyramid charts
  const { callApi: callRefreshOpportunityDataApi } =
    useRefreshOpportunityData();

  const subscribedPromises = useRef([]);
  const [isEditable, setIsEditable] = useState(false);
  const [labourChangeError, setLabourChangeError] = useState();
  const [labourChangeIsLoading, setLabourChangeIsLoading] = useState(false);
  const [labourDeleteError, setLabourDeleteError] = useState();
  const [labourDeleteIsLoading, setLabourDeleteIsLoading] = useState(false);

  const {
    id,
    careerLevel,
    country,
    employeeNumber,
    finalRate,
    localrate,
    name,
    rateDiscount,
    total,
    totalHours,
  } = labour;

  const [newRateDiscount, setNewRateDiscount] = useState(rateDiscount);
  const [newTotalHours, setNewTotalHours] = useState(totalHours);

  const handleLabourChangeSubmit = () => {
    setLabourChangeIsLoading(true);
    const labourChangePromise = put(
      `/activities/${activityId}/employees/${id}`,
      {
        rateDiscount: newRateDiscount,
        totalHours: newTotalHours,
      },
    );

    labourChangePromise.promise
      .then((response) => {
        updateData(response.data);
        setLabourChangeError(null);
        setIsEditable(false);
        callRefreshOpportunityDataApi();
      })
      .catch((error) => {
        !error.isCanceled && setLabourChangeError(error);
        console.log(error);
      })
      .finally(() => {
        setLabourChangeIsLoading(false);
      });

    subscribedPromises.current.push(labourChangePromise);
  };

  const handleLabourDeleteSubmit = () => {
    setLabourDeleteIsLoading(true);
    const labourDeletePromise = destroy(
      `/activities/${activityId}/employees/${id}`,
    );

    labourDeletePromise.promise
      .then((response) => {
        updateData(response.data);
        setLabourDeleteError(null);
        setIsEditable(false);
        callRefreshOpportunityDataApi();
      })
      .catch((error) => {
        !error.isCanceled && setLabourDeleteError(error);
      })
      .finally(() => {
        setLabourDeleteIsLoading(false);
      });

    subscribedPromises.current.push(labourDeletePromise);
  };

  return (
    <>
      <tr key={id} className="align-middle">
        <td>{index + 1}.</td>
        <td>{employeeNumber}</td>
        <td>{name}</td>
        <td>{country?.name}</td>
        <td>{careerLevel}</td>
        <td className="text-end">${localrate}</td>
        <td className="text-end" style={{ width: "50px" }}>
          {isEditable ? (
            <Form.Control
              type="number"
              size="sm"
              value={newRateDiscount}
              onChange={(e) => {
                setNewRateDiscount(e.target.value);
              }}
            />
          ) : (
            <>{rateDiscount}</>
          )}
        </td>
        <td className="text-end">${finalRate}</td>
        <td className="text-end" style={{ width: "50px" }}>
          {isEditable ? (
            <Form.Control
              type="number"
              size="sm"
              value={newTotalHours}
              onChange={(e) => {
                setNewTotalHours(e.target.value);
              }}
            />
          ) : (
            <>{totalHours}</>
          )}
        </td>
        <td className="text-end">${total}</td>
        <td className="text-end">
          <div className="d-flex flex-row h-100 w-100 justify-content-center">
            {isEditable ? (
              <Button
                size="sm"
                className="px-2 py-0 me-2"
                variant="outline-success"
                onClick={() => {
                  handleLabourChangeSubmit();
                }}
              >
                {labourChangeIsLoading ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  <span className="material-icons-outlined md-18">done</span>
                )}
              </Button>
            ) : (
              <Button
                size="sm"
                className="px-2 py-0 me-2"
                variant="outline-success"
                onClick={() => {
                  setIsEditable(true);
                }}
              >
                <span className="material-icons-outlined md-18">edit</span>
              </Button>
            )}
            <Button
              size="sm"
              className="px-2 py-0"
              variant="outline-danger"
              onClick={() => {
                handleLabourDeleteSubmit();
              }}
            >
              {labourDeleteIsLoading ? (
                <Spinner animation="border" size="sm" />
              ) : (
                <span className="material-icons-outlined md-18">delete</span>
              )}
            </Button>
          </div>
        </td>
      </tr>
      <tr>
        {labourChangeError && (
          <td colSpan={9999}>
            <ErrorHandler error={labourChangeError} />
          </td>
        )}
        {labourDeleteError && (
          <td colSpan={9999}>
            <ErrorHandler error={labourDeleteError} />
          </td>
        )}
      </tr>
    </>
  );
};

export default SingleLabour;
