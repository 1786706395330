import { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  Accordion,
  Alert,
  Card,
  Col,
  Nav,
  Row,
  Tab,
  ButtonGroup,
  Button,
  Tooltip,
  OverlayTrigger,
  Tabs,
} from "react-bootstrap";

import SinglePhaseWithCosting from "./SinglePhaseWithCosting";
import { useOpportunityCostingEquipments } from "contexts/OpportunityCostingProvider";
import { PhasesDispatchContext } from "contexts/PhasesProvider";
import { OpportunityContext } from "contexts/OpportunityProvider";
import LabourPyramidChart from "components/OpportunityCosting/Labour/LabourPyramidChart";
import LabourRevenueChart from "components/OpportunityCosting/Labour/LabourRevenueChart";
import CardHeader from "react-bootstrap/esm/CardHeader";
import LabourPieChart from "components/OpportunityCosting/Labour/LabourPieChart";
import LabourRevenuePieChart from "components/OpportunityCosting/Labour/LabourRevenuePieChart";
import { usePhases } from "contexts/PhasesProvider";

import CostingTable from "../CostingGrid/CostingTable";

const PhasesWithCosting = () => {
  const opportunity = useContext(OpportunityContext);
  const phases = usePhases();
  const opportunityCosting = useOpportunityCostingEquipments();

  const setPhases = useContext(PhasesDispatchContext);

  const [searchParams, setSearchParams] = useSearchParams();

  // type can be "equipmentAndSupplies", "labour",'Digital Product'
  const [type, setType] = useState(searchParams.get("type") || "labour");
  const [activeTab, setActiveTab] = useState("pyramid");

  const [costingView, setConstingView] = useState("accordion_view");

  useEffect(() => {
    setType(searchParams.get("type") || "labour");
  }, [searchParams]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const {
    summary: { careerLevelSummary, totalLaborHours, totalLaborCost },
  } = opportunity;

  const updatePhasesData = (phaseIndex, activityIndex, newData) => {
    let summary = {
      activityCount: 0,
      totalEquipmentCount: 0,
      totalEquipmentCost: 0,
      totalLaborCount: 0,
      totalLaborCost: 0,
      totalDigitalProductCount: 0,
      totalDigitalProductCost: 0,
    };
    let temp = [...phases];
    temp[phaseIndex].activities[activityIndex] = newData;
    temp[phaseIndex].activities.forEach((element) => {
      summary.totalEquipmentCount += element.summary.equipmentCount;
      summary.totalEquipmentCost += element.summary.totalEquipmentCost;
      summary.totalLaborCount += element.summary.laborCount;
      summary.totalLaborCost += element.summary.totalLaborCost;
      summary.totalDigitalProductCount += element.summary.digitalEquipmentCount;
      summary.totalDigitalProductCost +=
        element.summary.totalDigitalEquipmentCost;
    });
    summary.activityCount = temp[phaseIndex].activities.length;
    temp[phaseIndex].summary = summary;
    setPhases(temp);
  };

  const CostOverview = () => (
    <Card className="my-2 border-1">
      <div className="m-3">
        <div className="d-flex text-start justify-content-between">
          <span className="h5 p-2">Labour Cost Overview</span>
          <div className="d-flex px-2 py-2">
            <OverlayTrigger overlay={<Tooltip>Toggle chart view</Tooltip>}>
              <ButtonGroup>
                <button
                  className={`btn  btn-sm btn-${
                    activeTab === "pyramid" ? "primary" : "secondary"
                  }`}
                  onClick={() => handleTabChange("pyramid")}
                >
                  Pyramid Chart
                </button>
                <button
                  className={`btn btn-sm btn-${
                    activeTab === "pie-chart" ? "primary" : "secondary"
                  }`}
                  onClick={() => handleTabChange("pie-chart")}
                >
                  Pie Chart
                </button>
              </ButtonGroup>
            </OverlayTrigger>
          </div>
        </div>
        <p className="px-2">
          An overview of labour cost breakdown for this opportunity in hours and
          revenue %
        </p>
      </div>

      {activeTab === "pyramid" && (
        <Card className="border-0">
          <div className="container">
            <div className="row">
              <div className="col-sm">
                <CardHeader>
                  <h6 className="text-center">Hours %</h6>
                </CardHeader>
                <LabourPyramidChart
                  totalLaborHours={totalLaborHours}
                  careerLevelSummary={careerLevelSummary}
                />
              </div>
              <div className="col-sm">
                <CardHeader>
                  <h6 className="text-center">Revenue %</h6>
                </CardHeader>
                <LabourRevenueChart
                  totalLaborCost={totalLaborCost}
                  careerLevelSummary={careerLevelSummary}
                />
              </div>
            </div>
          </div>
        </Card>
      )}

      {activeTab === "pie-chart" && (
        <Card className="border-0">
          <div className="container">
            <div className="row">
              <div className="col-sm">
                <CardHeader>
                  <h5 className="text-center">Hours %</h5>
                </CardHeader>
                <LabourPieChart
                  totalLaborHours={totalLaborHours}
                  careerLevelSummary={careerLevelSummary}
                />
              </div>
              <div className="col-sm">
                <CardHeader>
                  <h5 className="text-center">Revenue %</h5>
                </CardHeader>
                <LabourRevenuePieChart
                  totalLaborCost={totalLaborCost}
                  careerLevelSummary={careerLevelSummary}
                />
              </div>
            </div>
          </div>
        </Card>
      )}
    </Card>
  );

  return (
    <>
      <Card>
        <Card.Body>
          <div className="d-flex flex-row items-center justify-content-between mt-4">
            <h3>Project Costing</h3>
            <div className="d-flex flex-row gap-2">
              <>
                <h5
                  className={
                    "bg-primary text-primary bg-opacity-25 px-3 py-2 text-center ms-1"
                  }
                >
                  {Intl.NumberFormat("en-us", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "USD",
                  }).format(opportunityCosting?.summary.labour.totalCost)}{" "}
                  <small>
                    For {opportunityCosting?.summary.labour.totalCount} Labours
                  </small>
                </h5>

                <h5
                  className={
                    "bg-info text-success bg-opacity-25 px-3 py-2 text-center ms-1"
                  }
                >
                  {Intl.NumberFormat("en-us", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "USD",
                  }).format(
                    opportunityCosting?.summary.equipmentAndSupplies.totalCost,
                  )}{" "}
                  <small>
                    For{" "}
                    {
                      opportunityCosting?.summary.equipmentAndSupplies
                        .totalCount
                    }{" "}
                    Equipments
                  </small>
                </h5>

                <h5
                  className={
                    "bg-secondary text-dark bg-opacity-10 px-3 py-2 text-center ms-1"
                  }
                >
                  {Intl.NumberFormat("en-us", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "USD",
                  }).format(
                    opportunityCosting?.summary.digitalProduct.totalCost,
                  )}{" "}
                  <small>
                    For {opportunityCosting?.summary.digitalProduct.totalCount}{" "}
                    Digital Products
                  </small>
                </h5>
                <h5
                  className={
                    "bg-success text-success bg-opacity-25 px-3 py-2 text-center ms-1"
                  }
                >
                  {Intl.NumberFormat("en-us", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "USD",
                  }).format(
                    opportunityCosting?.summary.digitalProduct.totalCost +
                      opportunityCosting?.summary.equipmentAndSupplies
                        .totalCost +
                      opportunityCosting?.summary.labour.totalCost,
                  )}{" "}
                  <small>Total Cost</small>
                </h5>
              </>
            </div>
          </div>
          <p>
            A breakdown of opportunity costs in labour, equipments and digital
            products
          </p>
          <hr />
          <div className="text-end my-2">
            {type === "equipmentAndSupplies" && (
              <>
                <span className="h5">
                  {opportunityCosting?.summary.equipmentAndSupplies.totalCount}{" "}
                  Equipments
                </span>
                <span
                  className={
                    [
                      opportunityCosting?.summary.equipmentAndSupplies
                        .totalCost > 0
                        ? "bg-success text-success"
                        : "bg-danger text-danger",
                    ] + " h5 bg-opacity-25 px-3 ms-3"
                  }
                >
                  {Intl.NumberFormat("en-us", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "USD",
                  }).format(
                    opportunityCosting?.summary.equipmentAndSupplies.totalCost,
                  )}
                </span>
              </>
            )}
            {type === "labour" && (
              <>
                <span className="h5 p-2">
                  {opportunityCosting?.summary.labour.totalCount} Labours
                </span>
                <span
                  className={
                    [
                      opportunityCosting?.summary.labour.totalCost > 0
                        ? "bg-success text-success"
                        : "bg-danger text-danger",
                    ] + " h5 bg-opacity-25 p-1 ms-3"
                  }
                >
                  {Intl.NumberFormat("en-us", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "USD",
                  }).format(opportunityCosting?.summary.labour.totalCost)}{" "}
                </span>
              </>
            )}
            {type === "digitalProduct" && (
              <>
                <span>
                  {opportunityCosting?.summary.digitalProduct.totalCount}{" "}
                  Digital Products
                </span>
                <span
                  className={
                    [
                      opportunityCosting?.summary.digitalProduct.totalCost > 0
                        ? "bg-success text-success"
                        : "bg-danger text-danger",
                    ] + " h5 bg-opacity-25 px-3 ms-3"
                  }
                >
                  {Intl.NumberFormat("en-us", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "USD",
                  }).format(
                    opportunityCosting?.summary.digitalProduct.totalCost,
                  )}
                </span>
              </>
            )}
            <ButtonGroup className="mx-2 pb-2">
              <OverlayTrigger overlay={<Tooltip>Accordion View</Tooltip>}>
                <Button
                  variant={
                    costingView === "accordion_view"
                      ? "primary"
                      : "outline-primary"
                  }
                  size="sm"
                  onClick={() => setConstingView("accordion_view")}
                >
                  <span className="material-icons fs-4 ">
                    auto_awesome_mosaic
                  </span>
                </Button>
              </OverlayTrigger>

              <OverlayTrigger overlay={<Tooltip>Grid View</Tooltip>}>
                <Button
                  variant={
                    costingView === "grid_view" ? "primary" : "outline-primary"
                  }
                  size="sm"
                  onClick={() => setConstingView("grid_view")}
                >
                  <span className="material-icons fs-4">grid_view</span>
                </Button>
              </OverlayTrigger>
            </ButtonGroup>{" "}
          </div>
          {Array.isArray(phases) && phases.length === 0 ? (
            <Alert variant="info" className={`my-3 d-flex flex-row`}>
              <div className="me-3">
                <span className="material-icons md-18 text-primary text-opacity-75">
                  info
                </span>
              </div>
              <div>
                <h5 className="mb-1">
                  <small>No Phases</small>
                </h5>
                <p className="mb-1">
                  <small>
                    There are currently no phases or activities to show.
                  </small>
                </p>
              </div>
            </Alert>
          ) : (
            <>
              {costingView === "accordion_view" && (
                <Tab.Container defaultActiveKey={type}>
                  <Row>
                    <Col sm={3} className="pe-0">
                      <Nav variant="pills" className="flex-column">
                        <Nav.Item>
                          <Nav.Link
                            onClick={() => {
                              setType("overview");
                              setSearchParams({ type: "overview" });
                            }}
                            eventKey="overview"
                            className="rounded-0"
                          >
                            Overview
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            onClick={() => {
                              setType("labour");
                              setSearchParams({ type: "labour" });
                            }}
                            eventKey="labour"
                            className="rounded-0"
                          >
                            Labour
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            onClick={() => {
                              setType("equipmentAndSupplies");
                              setSearchParams({ type: "equipmentAndSupplies" });
                            }}
                            eventKey="equipmentAndSupplies"
                            className="rounded-0"
                          >
                            Equipment and Supplies
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            onClick={() => {
                              setType("digitalProduct");
                              setSearchParams({ type: "digitalProduct" });
                            }}
                            eventKey="digitalProduct"
                            className="rounded-0"
                          >
                            Digital Products
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Col>
                    <Col sm={9} className="border-left p-0">
                      <div className="h-100 w-100">
                        <Tab.Content>
                          <Tab.Pane eventKey="overview">
                            <Accordion flush defaultActiveKey="0">
                              <CostOverview />
                            </Accordion>
                          </Tab.Pane>
                          <Tab.Pane eventKey="labour">
                            <Accordion flush defaultActiveKey="0">
                              {phases?.map((phase, index) => {
                                const { id } = phase;

                                return (
                                  <SinglePhaseWithCosting
                                    key={id}
                                    phase={phase}
                                    index={index}
                                    updatePhasesData={updatePhasesData}
                                    type="labour"
                                  />
                                );
                              })}
                            </Accordion>
                          </Tab.Pane>
                          <Tab.Pane eventKey="equipmentAndSupplies">
                            <Accordion flush defaultActiveKey="0">
                              {phases?.map((phase, index) => {
                                const { id } = phase;

                                return (
                                  <SinglePhaseWithCosting
                                    key={id}
                                    phase={phase}
                                    index={index}
                                    updatePhasesData={updatePhasesData}
                                    type="equipmentAndSupplies"
                                  />
                                );
                              })}
                            </Accordion>
                          </Tab.Pane>
                          <Tab.Pane eventKey="digitalProduct">
                            <Accordion flush defaultActiveKey="0">
                              {phases?.map((phase, index) => {
                                const { id } = phase;

                                return (
                                  <SinglePhaseWithCosting
                                    key={id}
                                    phase={phase}
                                    index={index}
                                    updatePhasesData={updatePhasesData}
                                    type="digitalProduct"
                                  />
                                );
                              })}
                            </Accordion>
                          </Tab.Pane>
                        </Tab.Content>
                      </div>
                    </Col>
                  </Row>
                </Tab.Container>
              )}
              {costingView === "grid_view" && (
                <Tabs
                  defaultActiveKey={type}
                  onSelect={(k) => {
                    setType(k);
                    setSearchParams({ type: k });
                  }}
                  className="mb-3"
                >
                  <Tab eventKey="overview" title="Overview">
                    <CostOverview />
                  </Tab>
                  <Tab eventKey="labour" title="Labour">
                    <CostingTable />
                  </Tab>
                  <Tab
                    eventKey="equipmentAndSupplies"
                    title="Equipment and Supplies"
                  >
                    <CostingTable />
                  </Tab>
                  <Tab eventKey="digitalProduct" title="Digital Products">
                    <CostingTable />
                  </Tab>
                </Tabs>
              )}
            </>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default PhasesWithCosting;
