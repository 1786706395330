import { faker } from "@faker-js/faker";
import { Alert, Badge, Button, Table } from "react-bootstrap";
import { Link } from "react-router-dom";

const SingleOpportunity = ({ opportunity }) => {
  const { sfNumber, title, updatedAt, inchargePartner, id } = opportunity;

  const industry = opportunity.industry;

  return (
    <tr className="align-middle">
      <td>{sfNumber}</td>
      <td className="text-capitalize">
        <span className="bg-success bg-opacity-10 text-success px-1">
          {title}
        </span>
      </td>

      <td>{inchargePartner.title ?? "N/A"}</td>
      <td>
        <Badge className="bg-light text-dark">
          {faker.helpers.arrayElement([
            "Contact",
            "Qualificatioin",
            "Costing",
            "Proposal",
            "Closing",
          ])}
        </Badge>
      </td>
      <td className="text-end">
        {faker.commerce.price({
          min: 10,
          max: 200,
          symbol: "$",
          dec: 0,
        })}
      </td>
      <td className="text-center">
        {(() => {
          let val = faker.number.float({
            min: 1.0,
            max: 20.0,
            precision: 0.01,
          });
          if (val < 5)
            return (
              <span className="bg-danger bg-opacity-10 text-danger px-2">
                {val}
              </span>
            );
          if (val < 15)
            return (
              <span className="bg-warning bg-opacity-10 text-warning px-2">
                {val}
              </span>
            );
          return (
            <span className="bg-success bg-opacity-10 text-success px-2">
              {val}
            </span>
          );
        })()}
      </td>
      <td>{industry}</td>
      <td>
        <small>
          {updatedAt}
          {/* {Intl.DateTimeFormat("en-US", {
            timeStyle: "medium",
            dateStyle: "short",
          }).format(updatedAt)} */}
        </small>
      </td>
      <td className="text-end">
        <Button
          size="sm"
          variant="outline-primary"
          as={Link}
          to={`opportunities/${id}`}
        >
          Review
        </Button>
      </td>
    </tr>
  );
};

const OpportunitiesTable = ({ opportunities }) => {
  if (opportunities?.length === 0) {
    return (
      <Alert variant="info" className={`my-3 d-flex flex-row`}>
        <div className="me-3">
          <span className="material-icons md-18 text-primary text-opacity-75">
            info
          </span>
        </div>
        <div>
          <h5 className="mb-1">
            <small>No Opportunites</small>
          </h5>
          <p className="mb-1">
            <small>
              There are currently no opportunities to show. Please add a new
              opportunity using the New Opportunity button
            </small>
          </p>
        </div>
      </Alert>
    );
  }

  return (
    <Table responsive size="sm ">
      <thead>
        <tr>
          <th>SF No.</th>
          <th>Project Title</th>
          <th>Partner in Charge</th>
          <th>Status</th>
          <th className="text-end">Revenue</th>
          <th className="text-end">Multiplier</th>
          <th>Industry</th>
          <th>Last Updated</th>
          <th className="text-end">Action</th>
        </tr>
      </thead>
      <tbody>
        {opportunities?.map((opportunity) => {
          const { id } = opportunity;
          return <SingleOpportunity key={id} opportunity={opportunity} />;
        })}
      </tbody>
    </Table>
  );
};

export default OpportunitiesTable;
