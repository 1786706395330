import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";
import { useCallGetDeApi } from "hooks/useCallApi";
import { useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import NewOpportunity from "components/Opportunity/NewOpportunity/NewOpportunity";
import OpportunitiesTable from "./OpportunitiesTable";

const Opportunities = () => {
  const {
    data: opportunities,
    error,
    isLoading,
  } = useCallGetDeApi("/opportunities");

  const [searchTerm, setSearchTerm] = useState("");

  if (error) return <ErrorHandler error={error} />;

  return (
    <Card className="mt-3 mb-3">
      <Card.Body>
        <div className="d-flex float-end gap-2">
          <Form.Control
            type="email"
            className="px-5"
            placeholder="Search Opportunities"
          />
          <div>
            <NewOpportunity />
          </div>
        </div>
        <h3>Recent Opportunites</h3>
        <hr />
        {isLoading ? (
          <Loader />
        ) : error ? (
          <ErrorHandler error={error} />
        ) : (
          <OpportunitiesTable opportunities={opportunities} />
        )}
        <p className="float-end">
          <Button variant="outline-dark" size="sm" className="px-3">
            Show More
          </Button>
        </p>
        <p className="text-muted">Showing {opportunities?.length}</p>
      </Card.Body>
    </Card>
  );
};

export default Opportunities;
