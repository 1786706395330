import { Form } from "react-bootstrap";

const DropdownField = ({
  handleChange,
  value,
  placeholder,
  onBlur,
  label,
  name,
  data,
  textKey,
  isLoading,
  disabled,
}) => {
  return (
    <Form.Group controlId={name} className="mb-3">
      <Form.Label className="mb-1">{label}</Form.Label>
      <Form.Select
        name={name}
        placeholder={placeholder}
        value={value || null}
        onChange={(e) => {
          handleChange(e.target.value);
        }}
        onBlur={onBlur}
        disabled={disabled}
      >
        <option value={null} disabled selected>
          {isLoading ? "Loading" : placeholder}
        </option>
        {Array.isArray(data) &&
          data.map((item) => {
            let { id } = item;
            return (
              <option key={id} value={id}>
                {item[textKey]}
              </option>
            );
          })}
      </Form.Select>
    </Form.Group>
  );
};

export default DropdownField;
