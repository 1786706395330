import { Accordion, Alert, Table } from "react-bootstrap";

import SingleLabour from "./SingleLabour";
import AddLabour from "./AddLabour/AddLabour";

const SingleActivityWithLabour = ({
  activityId,
  phaseIndex,
  activityIndex,
  activity,
  updateActivitiesData,
}) => {
  const { id, summary, title, labor } = activity;

  return (
    <Accordion flush>
      <Accordion.Item eventKey={activityId} key={activityId}>
        <Accordion.Header>
          <div className="d-flex w-100 flex-row justify-content-between">
            <span>
              {phaseIndex + 1}.{activityIndex + 1}. {title}
            </span>

            <div className="me-2">
              <span>{summary.laborCount} Labours</span>
              <span
                className={
                  [
                    summary.totalLaborCost > 0
                      ? "bg-success text-success"
                      : "bg-danger text-danger",
                  ] + " bg-opacity-25 px-3 ms-2"
                }
              >
                ${summary.totalLaborCost}
              </span>
            </div>
          </div>
        </Accordion.Header>
        <Accordion.Body className="border">
          <div>
            {Array.isArray(labor) && labor.length === 0 ? (
              <Alert variant="info" className={`d-flex flex-row`}>
                <div className="me-3">
                  <span className="material-icons md-18 text-primary text-opacity-75">
                    info
                  </span>
                </div>
                <div>
                  <h5 className="mb-1">
                    <small>No Labours</small>
                  </h5>
                  <p className="mb-1">
                    <small>There are currently no labours allocated.</small>
                  </p>
                </div>
              </Alert>
            ) : (
              <>
                <Table size="sm" responsive>
                  <thead>
                    <tr>
                      <th>Serial</th>
                      <th>Employee No.</th>
                      <th>Name</th>
                      <th>Working Org.</th>
                      <th>Career Level</th>
                      <th className="text-end">Local Rate</th>
                      <th className="text-end">Rate Discount</th>
                      <th className="text-end">Adjusted Rate</th>
                      <th className="text-end">Total Hours</th>
                      <th className="text-end">Total</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(labor) &&
                      labor?.map((singleLabour, singleLabourIndex) => {
                        const { id } = singleLabour;

                        return (
                          <SingleLabour
                            labour={singleLabour}
                            key={id}
                            index={singleLabourIndex}
                            activityId={activityId}
                            updateData={updateActivitiesData}
                          />
                        );
                      })}
                  </tbody>
                </Table>
              </>
            )}
          </div>
          <div className="text-end">
            <AddLabour activityId={id} updateData={updateActivitiesData} />
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default SingleActivityWithLabour;
