import { useState } from "react";
import { Accordion, Badge, useAccordionButton } from "react-bootstrap";

import CostingTableActivity from "./CostingTableActivity";
import { useCosting } from "contexts/CostingProvider";

import "./CostingTable.scss";

const CostingTablePhase = ({ phase, phaseIndex, costingType }) => {
  let { id, activities, title } = phase;
  const costingData = useCosting(costingType);

  const [isShow, setIsShow] = useState(false);
  const cellStyle = "border cell-width bg-light align-middle";
  const headingStyle =
    "border bg-light cell-heading-width align-middle cursor-pointer";

  const hoursStyle = [
    "bg-warning text-warning",
    "bg-primary text-primary",
    "bg-success text-success",
  ];

  const PhaseTitle = ({ eventKey, phaseIndex, title }) => {
    const decoratedOnClick = useAccordionButton(eventKey, () => {
      setIsShow(!isShow);
    });

    return (
      <td className={headingStyle} onClick={decoratedOnClick}>
        <span>{`${phaseIndex + 1}. ${title}`}</span>
        <span className="material-icons-outlined">expand_more</span>
      </td>
    );
  };

  const PhaseTotals = ({ eventKey, children }) => {
    const decoratedOnClick = useAccordionButton(eventKey, () => {
      setIsShow(!isShow);
    });

    return (
      <td className={cellStyle} onClick={decoratedOnClick}>
        {children}
      </td>
    );
  };

  return (
    <Accordion flush>
      <tr>
        <PhaseTitle eventKey={id} phaseIndex={phaseIndex} title={title} />
        {costingData.map((costingData) => {
          if (Object.keys(costingData).length === 1) {
            return (
              <PhaseTotals eventKey={id} key={costingData.id}>
                <span className="d-flex justify-content-end align-middle my-auto">
                  <Badge
                    className={
                      [hoursStyle[0]] + " rounded-0 py-1 px-2 bg-opacity-10"
                    }
                  >
                    <h6 className="mb-0">{0}</h6>
                  </Badge>
                </span>
              </PhaseTotals>
            );
          }
          return (
            <PhaseTotals eventKey={id} key={costingData.id}>
              <span className="d-flex justify-content-end align-middle my-auto">
                <Badge
                  className={
                    [
                      hoursStyle[
                        !costingData.phases[phaseIndex]?.totalHours
                          ? 0
                          : costingData.phases[phaseIndex]?.totalHours <= 25
                          ? 1
                          : 2
                      ],
                    ] + " rounded-0 py-1 px-2 bg-opacity-10"
                  }
                >
                  <h6 className="mb-0">
                    {costingData.phases[phaseIndex]?.totalHours || 0}
                  </h6>
                </Badge>
              </span>
            </PhaseTotals>
          );
        })}
      </tr>
      <Accordion.Collapse eventKey={id}>
        <>
          {activities.map((activity, activityIndex) => {
            let { id } = activity;

            return (
              <CostingTableActivity
                key={id}
                activity={activity}
                phaseIndex={phaseIndex}
                activityIndex={activityIndex}
              />
            );
          })}
        </>
      </Accordion.Collapse>
    </Accordion>
  );
};

export default CostingTablePhase;
