import React, { useEffect } from "react";
import * as echarts from "echarts/lib/echarts.js";

const LabourPyramidChart = ({ careerLevelSummary, totalLaborHours }) => {
  useEffect(() => {
    const filteredData = careerLevelSummary
      .filter((level) => level.hoursPercentage > 0) // Filter out data with 0 value
      .map((level) => {
        return {
          value: (level.hoursPercentage / totalLaborHours) * 100,
          name: level.title,
        };
      });
    const chart = echarts.init(document.getElementById("pyramid-chart"));
    //  console.log('title',title)
    //  console.log('hours percentage',hoursPercentage)
    const option = {
      tooltip: {
        trigger: "item",
        formatter: function (params) {
          const formattedValue = parseFloat(params.value).toFixed(2);
          return params.name + ": " + formattedValue + "%";
        },
      },
      series: [
        {
          type: "funnel",
          left: "10%",
          width: "80%",
          maxSize: "50%",
          sort: "ascending",
          gap: 2,
          funnelAlign: "center",
          label: {
            show: true,
            position: "inside",
          },
          labelLine: {
            length: 10,
            lineStyle: {
              width: 1,
              type: "solid",
            },
          },
          itemStyle: {
            borderColor: "#fff",
            borderWidth: 1,
          },
          emphasis: {
            label: {
              fontSize: 20,
            },
          },
          data: filteredData,
        },
      ],
    };

    chart.setOption(option);

    return () => {
      chart.dispose();
    };
  }, [careerLevelSummary]);

  return <div id="pyramid-chart" style={{ width: "100%", height: "500px" }} />;
};

export default LabourPyramidChart;
