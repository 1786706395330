import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";
import { PhasesDispatchContext } from "contexts/PhasesProvider";
import { useCallGetDeApi } from "hooks/useCallApi";
import { useOpportunityCostingDispatch } from "contexts/OpportunityCostingProvider";
import { usePhases } from "contexts/PhasesProvider";
import { CostingProvider } from "contexts/CostingProvider";

const PhasesLayout = ({ children }) => {
  const phases = usePhases();
  const setPhases = useContext(PhasesDispatchContext);
  const dispatch = useOpportunityCostingDispatch();

  let { opportunityId } = useParams();
  const { data, isLoading, error } = useCallGetDeApi(`/phases`, {
    params: {
      opportunityId: opportunityId,
    },
  });

  useEffect(() => {
    setPhases(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (phases) {
      phases.forEach((phase) => {
        phase.activities.forEach((activity) => {
          dispatch({
            type: "addActivities",
            activity: activity,
          });
        });
      });
    }
  }, [phases]);

  if (isLoading) return <Loader />;
  if (error) return <ErrorHandler error={error} />;
  if (phases) {
    return <CostingProvider>{children}</CostingProvider>;
  }
};

export default PhasesLayout;
