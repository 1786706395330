import BreadCrumbs from "components/BreadCrumb/BreadCrumb";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";
import DeleteOpportunity from "components/Opportunity/DeleteOpportunity/DeleteOpportunity";
import { BreadcrumbContext } from "contexts/OpportunityProvider";
import { ActiveTabContext } from "contexts/OpportunityProvider";
import { OpportunityDispatchContext } from "contexts/OpportunityProvider";
import { OpportunityContext } from "contexts/OpportunityProvider";
import { useCallGetDeApi } from "hooks/useCallApi";
import { useContext, useEffect } from "react";
import { Badge, Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";

const OpportunityLayout = ({ children }) => {
  const opportunity = useContext(OpportunityContext);
  const setOpportunity = useContext(OpportunityDispatchContext);
  const breadcrumbs = useContext(BreadcrumbContext);

  /*
    This is used to outline which tab is selected in the navigation component below
    the values can be "projectSummary", "wbs", "costing", "dashboard"
  */
  const activeTab = useContext(ActiveTabContext);

  let { opportunityId } = useParams();

  const { data, isLoading, error } = useCallGetDeApi(
    `/opportunities/${opportunityId}`,
  );

  useEffect(() => {
    setOpportunity(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (isLoading) return <Loader />;
  if (error) return <ErrorHandler error={error} />;
  if (opportunity) {
    const { updatedAt, title, description, organization } = opportunity;

    return (
      <>
        <BreadCrumbs breadcrumbs={breadcrumbs} />

        {/* NAVIGATION */}
        <div className="d-flex float-end gap-2">
          <Button
            as={Link}
            to={`/opportunities/${opportunityId}`}
            variant={activeTab === "dashboard" ? "primary" : "outline-primary"}
            size="sm"
          >
            Dashboard
          </Button>
          <Button
            as={Link}
            to={`/opportunities/${opportunityId}/wbs`}
            variant={activeTab === "wbs" ? "primary" : "outline-primary"}
            size="sm"
          >
            Work Breakdown
          </Button>

          <Button
            as={Link}
            to={`/opportunities/${opportunityId}/costing?type=overview`}
            variant={activeTab === "costing" ? "primary" : "outline-primary"}
            size="sm"
          >
            Costing
          </Button>

          <Button
            as={Link}
            to={`/opportunities/${opportunityId}/summary`}
            variant={
              activeTab === "projectSummary" ? "primary" : "outline-primary"
            }
            size="sm"
          >
            Project Summary
          </Button>

          <DeleteOpportunity title={title} opportunityId={opportunityId} />
        </div>

        <h3>
          {title}{" "}
          {organization.title && (
            <small className="bg-success bg-opacity-10 text-success px-1">
              {organization.title}
            </small>
          )}
        </h3>
        <h5>{description}</h5>
        <br />
        <i className="me-3">
          <small>
            Last Updated At{" "}
            {new Date(updatedAt).toLocaleString([], {
              dateStyle: "short",
              timeStyle: "short",
            })}
          </small>
        </i>
        <Badge className="bg-info">Proposal</Badge>

        <hr />

        {children}
      </>
    );
  }
};

export default OpportunityLayout;
