import DropdownField from "components/Inputs/DropdownField";
// import InputField from "../../Inputs/InputField";
import { useCallGetDeApi } from "hooks/useCallApi";
import { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Container, Row, Spinner } from "react-bootstrap";
import { put } from "utils/DeApi";

const ProjectInformation = ({
  information,
  opportunityId,
  setOpportunityDetails,
}) => {
  const subscribedPromises = useRef([]);
  const [isEditable, setIsEditable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    // sfNumber,
    inchargePartner,
    // clientContact,
    projectManager,
    pricingType,
    subService,
    // projectLocation,
    projectStartDate,
    contractTerm,
    currency,
    tenderCompetition,
    // projectEndDate,
  } = information;

  // let formattedProjectStartDate = new Date(projectStartDate)
  //   .toISOString()
  //   .split("T")[0];
  // let formattedProjectEndDate = new Date(projectEndDate)
  //   .toISOString()
  //   .split("T")[0];

  // const [newSfNumber, setNewSfNumber] = useState(sfNumber);
  const [newInchargePartnerId, setNewInchargePartnerId] = useState(
    inchargePartner.id,
  );
  // const [newClientContact, setNewClientContact] = useState(clientContact);
  const [newProjectManagerId, setNewProjectManagerId] = useState(
    projectManager.id,
  );
  // const [newProjectLocation, setNewProjectLocation] = useState(projectLocation);
  // const [newProjectStartDate, setNewProjectStartDate] = useState(
  //   formattedProjectStartDate
  // );
  // const [newProjectEndDate, setNewProjectEndDate] = useState(
  //   formattedProjectEndDate
  // );

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, []);

  const {
    // callApi: callPartnersApi,
    data: partnersData,
    // error: partnersError,
    // isLoading: partnerIsLoading,
  } = useCallGetDeApi("/partners-in-charge");

  const {
    // callApi: callProjectManagersApi,
    data: projectManagersData,
    // error: projectManagersError,
    // isLoading: projectManagersIsLoading,
  } = useCallGetDeApi("/project-managers");

  const handleProjectInChargeSubmit = () => {
    setIsLoading(true);
    const projectInChargePromise = put(
      `/opportunities/${opportunityId}/incharge-partner`,
      {
        id: newInchargePartnerId,
      },
    );

    projectInChargePromise.promise
      .then((response) => {
        setOpportunityDetails(response.data);
      })
      .catch((error) => {
        // !error.isCanceled && setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });

    subscribedPromises.current.push(projectInChargePromise);
  };

  const handleProjectManagerSubmit = () => {
    setIsLoading(true);
    const projectManagerPromise = put(
      `/opportunities/${opportunityId}/project-manager`,
      {
        id: newProjectManagerId,
      },
    );

    projectManagerPromise.promise
      .then((response) => {
        setOpportunityDetails(response.data);
      })
      .catch((error) => {
        // !error.isCanceled && setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });

    subscribedPromises.current.push(projectManagerPromise);
  };

  return (
    <Card className="my-3">
      <Card.Body>
        <div className="d-flex justify-content-between">
          <h4>Project Information</h4>
          <div className="d-flex"></div>
        </div>
        <hr />
        {isEditable ? (
          <Container>
            <Row>
              <Col>
                <DropdownField
                  data={partnersData}
                  textKey="title"
                  name="newInchargePartnerId"
                  label={"Partner in Charge"}
                  handleChange={setNewInchargePartnerId}
                  placeholder={"Select Partner in Charge"}
                  value={newInchargePartnerId}
                  onBlur={handleProjectInChargeSubmit}
                />
              </Col>

              <Col>
                <DropdownField
                  data={projectManagersData}
                  textKey="title"
                  name="newProjectManagerId"
                  label={"Project Manager"}
                  handleChange={setNewProjectManagerId}
                  placeholder={"Select Project Manager"}
                  value={newProjectManagerId}
                  onBlur={handleProjectManagerSubmit}
                />
              </Col>
            </Row>
          </Container>
        ) : (
          <Container>
            <Row>
              <Col>
                <span>Partner in Charge</span>
                <h5>{inchargePartner.title}</h5>
              </Col>
              <Col>
                <span>Project Manager</span>
                <h5>{projectManager.title}</h5>
              </Col>
              {subService && (
                <Col>
                  <span>Sub-Service</span>
                  <h5>{subService.title}</h5>
                </Col>
              )}

              {pricingType && (
                <Col>
                  <span>Pricing Type</span>
                  <h5>{pricingType ? pricingType.title : ""}</h5>
                </Col>
              )}
              {projectStartDate && (
                <Col>
                  <span>Start Date</span>
                  <h5>{projectStartDate ? projectStartDate : ""}</h5>
                </Col>
              )}

              {currency && (
                <Col>
                  <span>Currency</span>
                  <h5>{currency ? currency.title : ""}</h5>
                </Col>
              )}

              {contractTerm && (
                <Col>
                  <span>Contract Terms</span>
                  <h5>{contractTerm ? contractTerm.title : ""}</h5>
                </Col>
              )}

              {tenderCompetition && (
                <Col>
                  <span>Tender Competition</span>
                  <h5>{tenderCompetition ? tenderCompetition.title : ""}</h5>
                </Col>
              )}
            </Row>
          </Container>
        )}
      </Card.Body>
    </Card>
  );
};

export default ProjectInformation;
