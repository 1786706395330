import React from "react";
import { useCallGetDeApi } from "hooks/useCallApi";
import DropdownFieldFormik from "components/Inputs/DropdownFieldFormik";
import InputFieldFormik from "components/Inputs/InputFieldFormik";

export const EquipmentCategoryField = React.memo(
  ({ handleBlur, handleChange, fieldValue }) => {
    const {
      data: equipmentCategoriesData,
      isLoading: equipmentCategoriesIsLoading,
    } = useCallGetDeApi("/equipment-categories");

    return (
      <DropdownFieldFormik
        data={equipmentCategoriesData}
        textKey="title"
        name="equipmentCategoriesId"
        placeholder={"Select Equipment Category"}
        isLoading={equipmentCategoriesIsLoading}
        handleChange={handleChange}
        handleBlur={handleBlur}
        value={fieldValue}
      />
    );
  },
);

export function EquipmentSelectField({
  formikBag,
  setSelectedEquipment,
  equipmentsData,
}) {
  const { data: equipmentData, isLoading: equipmentIsLoading } =
    useCallGetDeApi(
      `/equipment-categories/${formikBag.values.equipmentCategoriesId}/equipment`,
    );
  return (
    <DropdownFieldFormik
      data={equipmentData}
      textKey="title"
      name="equipmentId"
      error={formikBag.errors.equipmentId}
      handleBlur={formikBag.handleBlur}
      handleChange={(e) => {
        setSelectedEquipment([
          ...equipmentsData.filter((eq) => eq.id !== "new"),
          {
            phases: [],
            ...equipmentData?.find((eq) => eq.id === e.target.value),
          },
        ]);
        formikBag.handleChange(e);
        formikBag.resetForm();
      }}
      placeholder={"Select Equipment"}
      touched={formikBag.touched.equipmentId}
      value={formikBag.values.equipmentId}
      isLoading={equipmentIsLoading}
    />
  );
}

export function QuantityField({ formikBag }) {
  return (
    <InputFieldFormik
      name="equipmentQuantity"
      label={"Equipment Quantity"}
      type="number"
      error={formikBag.errors.equipmentQuantity}
      handleBlur={formikBag.handleBlur}
      handleChange={formikBag.handleChange}
      placeholder={"0"}
      touched={formikBag.touched.equipmentQuantity}
      value={formikBag.values.equipmentQuantity}
    />
  );
}
