import { useContext } from "react";

import { OpportunityContext } from "contexts/OpportunityProvider";
import { BreadcrumbDispatchContext } from "contexts/OpportunityProvider";
import { ActiveTabDispatchContext } from "contexts/OpportunityProvider";
import PhasesAndActivitiesCostingChart from "./PhasesAndActivitiesCostingChart/PhasesAndActivitiesCostingChart";

const OpportunitySummary = () => {
  const opportunity = useContext(OpportunityContext);
  const setBreadcrumbs = useContext(BreadcrumbDispatchContext);
  const setActiveTab = useContext(ActiveTabDispatchContext);

  if (opportunity) {
    const { id, title } = opportunity;

    setBreadcrumbs([
      { name: "Dashboard", link: "/" },
      { name: "Opportunities", active: false },
      { name: title, link: `/opportunities/${id}` },
      { name: "Project Summary", active: false },
    ]);
    setActiveTab("projectSummary");

    return (
      <>
        <PhasesAndActivitiesCostingChart />
      </>
    );
  }
};

export default OpportunitySummary;
