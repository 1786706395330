import { useState } from "react";
import {
  Accordion,
  Alert,
  Button,
  Form,
  Spinner,
  Table,
  useAccordionButton,
} from "react-bootstrap";
import { put } from "utils/DeApi";
import DeletePhase from "./DeletePhase";
import SingleActivity from "./Activity/SingleActivity";
import AddActivity from "./Activity/AddActivity";

const SinglePhase = ({ phase, index, refreshList }) => {
  const { id, title, activities, createdAt, updatedAt } = phase;

  const [isEditable, setIsEditable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [activitiesData, setActivitiesData] = useState(activities);

  const [newTitle, setNewTitle] = useState(title);

  const handleTitleSubmit = (title) => {
    setIsLoading(true);

    const phaseTitlePromise = put(`/phases/${id}`, {
      title: title,
      description: "",
    });

    phaseTitlePromise.promise
      .then((response) => {
        setIsEditable(false);
        refreshList();
      })
      .catch((error) => {
        // !error.isCanceled && setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });

    // subscribedPromises.current.push(projectInChargePromise);
  };

  const updateActivitiesData = (newData) => {
    setActivitiesData([...activitiesData, newData]);
  };

  return (
    <Accordion.Item eventKey={index} key={id}>
      <div className="my-3 d-flex flex-row align-items-center justify-content-between">
        {/* PHASE TITLE  */}
        <div className="d-flex flex-row align-items-center">
          <span className="fs-5">{index + 1}. </span>
          {isEditable ? (
            <Form.Control
              className="ms-2"
              value={newTitle}
              onChange={(e) => {
                setNewTitle(e.target.value);
              }}
            />
          ) : (
            <span className="ms-2 fs-5">{title}</span>
          )}{" "}
          <span
            onClick={useAccordionButton(index)}
            className="material-icons-outlined cursor-pointer"
          >
            expand_more
          </span>
        </div>

        {/* BUTTON GROUP */}
        <div className="d-flex flex-row align-items-center gap-2">
          <span onClick={useAccordionButton(index)} className="cursor-pointer">
            {activitiesData.length} Activities
          </span>
          {/* <big className="bg-success bg-opacity-25 px-3 ms-3 text-sucess">
                    10 hrs
                  </big> */}
          <div className="d-flex">
            {isEditable ? (
              <>
                <Button
                  size="sm"
                  variant="outline-success"
                  className="me-2"
                  disabled={isLoading}
                  onClick={() => {
                    handleTitleSubmit(newTitle);
                  }}
                >
                  {isLoading ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    <span className="material-icons-outlined md-18">done</span>
                  )}
                </Button>
                <Button
                  onClick={() => {
                    setIsEditable(false);
                  }}
                  size="sm"
                  variant="outline-danger"
                >
                  <span className="material-icons-outlined md-18">cancel</span>
                </Button>
              </>
            ) : (
              <>
                <Button
                  size="sm"
                  className="me-2"
                  variant="outline-warning"
                  onClick={() => {
                    setIsEditable(true);
                  }}
                >
                  <span className="material-icons-outlined md-18">edit</span>
                </Button>
                <DeletePhase
                  phaseId={id}
                  refreshList={refreshList}
                  title={title}
                />
              </>
            )}
          </div>
        </div>
      </div>

      <Accordion.Collapse className="border-top" eventKey={index}>
        <Table responsive size="sm" striped>
          <tbody>
            {Array.isArray(activitiesData) && activitiesData.length === 0 ? (
              <tr>
                <td colSpan="100" className="border-0">
                  <Alert variant="info" className={`d-flex flex-row`}>
                    <div className="me-3">
                      <span className="material-icons md-18 text-primary text-opacity-75">
                        info
                      </span>
                    </div>
                    <div>
                      <h5 className="mb-1">
                        <small>No Activities</small>
                      </h5>
                      <p className="mb-1">
                        <small>
                          There are currently no activities to show.
                        </small>
                      </p>
                    </div>
                  </Alert>
                </td>
              </tr>
            ) : (
              <>
                {Array.isArray(activitiesData) &&
                  activitiesData?.map((activity, activityIndex) => {
                    const { id } = activity;

                    return (
                      <SingleActivity
                        key={id}
                        activity={activity}
                        phaseIndex={index}
                        activityIndex={activityIndex}
                        refreshList={refreshList}
                      />
                    );
                  })}
              </>
            )}
          </tbody>
          <tfoot>
            <tr className="bg-white">
              <td colSpan="100" className="text-end py-3 border-0 bg-white">
                <AddActivity
                  phaseId={id}
                  updateActivitiesData={updateActivitiesData}
                />
              </td>
            </tr>
          </tfoot>
        </Table>
      </Accordion.Collapse>
    </Accordion.Item>
  );
};

export default SinglePhase;
