import { Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import {
  Modal,
  Button,
  Form,
  Spinner,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { number, object, string } from "yup";

import { useCallGetDeApi } from "../../../../../hooks/useCallApi";
import ErrorHandler from "../../../../ErrorHandler/ErrorHandler";
import InputFieldFormik from "../../../../Inputs/InputFieldFormik";
import DropdownFieldFormik from "../../../../Inputs/DropdownFieldFormik";
import DropdownField from "components/Inputs/DropdownField";
import InputField from "components/Inputs/InputField";
import { post } from "utils/DeApi";
import { useRefreshOpportunityData } from "helpers/OpportunityHelpers";

const AddLabourModal = ({ show, handleClose, activityId, updateData }) => {
  // To refresh the opportunity details api data to get updated values for the pyramid charts
  const { callApi: callRefreshOpportunityDataApi } =
    useRefreshOpportunityData();

  const subscribedPromises = useRef([]);
  const [countryId, setCountryId] = useState("");
  const [labour, setLabour] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const addLabour = (values) => {
    setError(null);
    setIsLoading(true);

    const addLabourPromise = post(`/activities/${activityId}/employees`, {
      employeeId: values.employeeId,
      rateDiscount: values.rateDiscount, // can be null
      totalHours: values.totalHours,
    });
    addLabourPromise.promise
      .then((response) => {
        handleClose();
        updateData(response.data);
        callRefreshOpportunityDataApi();
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });

    subscribedPromises.current.push(addLabourPromise);
  };

  const {
    data: countriesData,
    error: countriesError,
    isLoading: countriesIsLoading,
  } = useCallGetDeApi("/countries");

  const { data: employeesData, isLoading: employeesIsLoading } =
    useCallGetDeApi(`/employees?countryId=${countryId}`);

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => promise.cancel());
    };
  }, []);

  const schema = object({
    employeeId: string().required("Employee is required"),
    rateDiscount: number().moreThan(-1).notRequired(),
    totalHours: number()
      .moreThan(0)
      .required("Hours greater than 0 is required"),
  });

  const initialValues = {
    employeeId: "",
    rateDiscount: 0, // can be null
    totalHours: 1,
  };

  return (
    <Modal show={show} onHide={handleClose} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Add Labour</Modal.Title>
      </Modal.Header>
      <Formik
        validationSchema={schema}
        onSubmit={(values) => {
          addLabour(values);
        }}
        initialValues={initialValues}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
        }) => {
          setLabour(
            Array.isArray(employeesData) &&
              employeesData.find((item) => {
                return item.id === values.employeeId;
              }),
          );

          return (
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                <DropdownField
                  data={countriesData}
                  textKey="name"
                  label={"Working Organization"}
                  placeholder={"Select Working Organization"}
                  isLoading={countriesIsLoading}
                  handleChange={setCountryId}
                />
                {countryId && (
                  <>
                    <DropdownFieldFormik
                      data={employeesData}
                      textKey="name"
                      name="employeeId"
                      label={"Employees"}
                      error={errors.employeeId}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      placeholder={"Select Employee"}
                      touched={touched.employeeId}
                      value={values.employeeId}
                      isLoading={employeesIsLoading}
                    />
                    {values.employeeId && (
                      <>
                        <Container>
                          <Row>
                            <Col>
                              <InputField
                                layout="row"
                                label="Career Level"
                                disabled={true}
                                value={labour?.careerLevel}
                              />
                            </Col>
                            <Col>
                              <InputField
                                layout="row"
                                label="Local Rate"
                                disabled={true}
                                value={labour?.localRate}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <InputField
                                layout="row"
                                label="Employee Number"
                                disabled={true}
                                value={labour?.employeeNumber}
                              />
                            </Col>
                            <Col>
                              <InputField
                                layout="row"
                                label="Working Ord ID"
                                disabled={true}
                                value={labour?.workingOrgId}
                              />
                            </Col>
                          </Row>
                        </Container>
                        <Row>
                          <Col>
                            <InputFieldFormik
                              name="rateDiscount"
                              label={"Rate Discount"}
                              type="number"
                              error={errors.rateDiscount}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              placeholder={"0"}
                              touched={touched.rateDiscount}
                              value={values.rateDiscount}
                            />
                          </Col>
                          <Col>
                            <InputFieldFormik
                              name="totalHours"
                              label={"Total Hours"}
                              type="number"
                              error={errors.totalHours}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              placeholder={"0"}
                              touched={touched.totalHours}
                              value={values.totalHours}
                            />
                          </Col>
                        </Row>
                      </>
                    )}
                  </>
                )}
                {error && <ErrorHandler error={error} />}
                {countriesError && <ErrorHandler error={countriesError} />}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size="sm"
                  variant="secondary"
                  className="bg-secondary bg-opacity-25 text-secondary border-0 fw-medium"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  size="sm"
                  disabled={
                    Object.keys(errors).length > 0 || isLoading || !isValid
                  }
                >
                  {isLoading && (
                    <Spinner
                      className="me-2"
                      animation="border"
                      size="sm"
                      variant="light"
                    />
                  )}{" "}
                  Add Labour
                </Button>
              </Modal.Footer>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};
export default AddLabourModal;
