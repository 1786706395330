import * as echarts from "echarts";
import { useEffect, useRef, useState } from "react";
import {
  Accordion,
  Badge,
  Col,
  Container,
  Row,
  useAccordionButton,
} from "react-bootstrap";

const BarChart = ({ values }) => {
  const el = useRef(null);

  useEffect(() => {
    const option = {
      grid: {
        left: "0px",
        right: "15px",
      },
      xAxis: {
        type: "category",
        data: ["Labour", "Equipment & Supplies", "Digital Products"],
        show: false,
      },
      yAxis: {
        show: false,
        type: "value",
      },
      series: [
        {
          data: values,
          type: "bar",
        },
      ],
    };

    const chart = echarts.init(el.current, null, {
      width: 80,
      height: 50,
    });

    chart.setOption(option);

    function handleResize() {
      chart.resize({
        width: 80,
        height: 50,
      });
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      chart.dispose();
    };
  }, [el, values]);

  return (
    <div className="chart-container">
      <div className="w-100" ref={el} />
    </div>
  );
};

const SingleActivity = ({ activity, parentIndex, index }) => {
  let { title, summary } = activity;

  const [isHover, setIsHover] = useState(false);

  return (
    <Container
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
      className={"my-2 " + [isHover && "bg-secondary bg-opacity-10"]}
    >
      <Row className="">
        <Col xs={6}>{`${parentIndex + 1}.${index + 1} ${title}`}</Col>
        <Col className="d-flex justify-content-end align-middle h-100">
          {Intl.NumberFormat("en-us", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
            style: "currency",
            currency: "USD",
          }).format(summary.totalLaborCost)}
        </Col>
        <Col className="d-flex justify-content-end align-middle h-100">
          {Intl.NumberFormat("en-us", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
            style: "currency",
            currency: "USD",
          }).format(summary.totalEquipmentCost)}
        </Col>
        <Col className="d-flex justify-content-end align-middle h-100">
          {Intl.NumberFormat("en-us", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
            style: "currency",
            currency: "USD",
          }).format(summary.totalDigitalEquipmentCost)}
        </Col>
      </Row>
    </Container>
  );
};

const SinglePhasesAndActivitiesCostingChart = ({ phase, index }) => {
  let { id, activities, summary, title } = phase;

  console.log(summary);

  const [isShow, setIsShow] = useState(false);

  let barChartValues = [
    {
      value: summary.totalLaborCost,
      itemStyle: {
        color: "#0D6EFD",
      },
    },
    {
      value: summary.totalEquipmentCost,
      itemStyle: {
        color: "#198754",
      },
    },
    {
      value: summary.totalDigitalEquipmentCost,
      itemStyle: {
        color: "#c6cc58",
      },
    },
  ];

  const CustomToggle = ({ eventKey }) => {
    const decoratedOnClick = useAccordionButton(eventKey, () => {
      setIsShow(!isShow);
    });

    return (
      <span
        className="material-icons-outlined cursor-pointer"
        onClick={decoratedOnClick}
      >
        expand_more
      </span>
    );
  };

  return (
    <Accordion className={"my-2 p-2 " + [isShow && "bg-light rounded"]} flush>
      <Row className="align-middle">
        <Col xs={6}>
          <div>
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <span>{`${index + 1}. ${title}`}</span>
                <CustomToggle eventKey={id} />
              </div>
              <BarChart values={barChartValues} />
            </div>
          </div>
        </Col>
        <Col>
          <span className="d-flex justify-content-end align-middle my-auto">
            <Badge className="bg-primary rounded-0 py-1 px-2 bg-opacity-10 text-primary">
              <h6 className="mb-0">
                {Intl.NumberFormat("en-us", {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                  style: "currency",
                  currency: "USD",
                }).format(summary.totalLaborCost)}
              </h6>
            </Badge>
          </span>
        </Col>
        <Col>
          <span className="d-flex justify-content-end align-middle my-auto">
            <Badge className="bg-success rounded-0 py-1 px-2 bg-opacity-10 text-success">
              <h6 className="mb-0">
                {Intl.NumberFormat("en-us", {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                  style: "currency",
                  currency: "USD",
                }).format(summary.totalEquipmentCost)}
              </h6>
            </Badge>
          </span>
        </Col>
        <Col>
          <span className="d-flex justify-content-end align-middle my-auto">
            <Badge className="bg-success rounded-0 py-1 px-2 bg-opacity-10 text-success">
              <h6 className="mb-0">
                {Intl.NumberFormat("en-us", {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                  style: "currency",
                  currency: "USD",
                }).format(summary.totalDigitalEquipmentCost)}
              </h6>
            </Badge>
          </span>
        </Col>
        <Accordion.Collapse eventKey={id}>
          <>
            {activities.map((activity, activityIndex) => {
              let { id } = activity;

              return (
                <SingleActivity
                  key={id}
                  activity={activity}
                  parentIndex={index}
                  index={activityIndex}
                />
              );
            })}
          </>
        </Accordion.Collapse>
      </Row>
    </Accordion>
  );
};

export default SinglePhasesAndActivitiesCostingChart;
