import React from "react";
import { useSearchParams } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useFormik } from "formik";

import { useCostingDispatch, useLabourCosting } from "contexts/CostingProvider";

import { cellStyle, headingStyle, costingTypesMap } from "./common";

import {
  WorkingOrgField,
  EmployeeSelectField,
  RateDiscountField,
} from "components/PhasesWithCosting/ActivityWithCosting/Labour/AddLabour/AddLabourFields";

export default function CostingTableLabourHeader() {
  const [searchParams] = useSearchParams();

  const newLabourCostingFormikBag = useFormik({
    initialValues: { countryId: "", rateDiscount: "", employeeId: "" },
  });

  const costingType = costingTypesMap[searchParams.get("type")];
  const setLabour = useCostingDispatch(searchParams.get("type"));
  const labourData = useLabourCosting();

  return (
    <>
      <tr>
        <td className={headingStyle} xs={6}>
          Project Role
        </td>
        {labourData.map((labour) => {
          if (Object.keys(labour).length === 1) {
            return <td key={labour.id} className={cellStyle}></td>;
          }
          return (
            <td key={labour.id} className={cellStyle}>
              {labour.careerLevel}
            </td>
          );
        })}
        <span className="px-0">
          <OverlayTrigger overlay={<Tooltip>Add {costingType}</Tooltip>}>
            <span className="btn">
              <span
                className="material-icons-outlined"
                onClick={() =>
                  setLabour([
                    ...labourData.filter((ld) => ld.id !== "new"),
                    { id: "new" },
                  ])
                }
              >
                add
              </span>
            </span>
          </OverlayTrigger>
        </span>
      </tr>
      <tr>
        <td className={headingStyle} xs={6}>
          Working Org
        </td>
        {labourData.map((labour) => {
          if (Object.keys(labour).length === 1) {
            return (
              <td key={labour.id} className={cellStyle}>
                <WorkingOrgField
                  handleChange={newLabourCostingFormikBag.handleChange}
                  handleBlur={newLabourCostingFormikBag.handleBlur}
                  fieldValue={newLabourCostingFormikBag.values.countryId}
                />
              </td>
            );
          }
          return (
            <td key={labour.id} className={cellStyle}>
              {labour.country.name}
            </td>
          );
        })}
      </tr>
      <tr>
        <td className={headingStyle} xs={6}>
          Local Currency
        </td>
        {labourData.map((labour) => {
          if (Object.keys(labour).length === 1) {
            return (
              <td key={labour.id} className={cellStyle}>
                USD
              </td>
            );
          }
          return (
            <td key={labour.id} className={cellStyle}>
              USD
            </td>
          );
        })}
      </tr>
      <tr>
        <td className={headingStyle} xs={6}>
          Staff Name
        </td>
        {labourData.map((labour) => {
          if (Object.keys(labour).length === 1) {
            return (
              <td key={labour.id} className={cellStyle}>
                <EmployeeSelectField
                  formikBag={newLabourCostingFormikBag}
                  setSelectedLabour={setLabour}
                  labourData={labourData}
                />
              </td>
            );
          }
          return (
            <td key={labour.id} className={cellStyle}>
              {labour.name}
            </td>
          );
        })}
      </tr>
      <tr>
        <td className={headingStyle} xs={6}>
          % Labour Adjustments
        </td>
        {labourData.map((labour) => {
          if (Object.keys(labour).length === 1) {
            return (
              <td key={labour.id} className={cellStyle}>
                <RateDiscountField formikBag={newLabourCostingFormikBag} />
              </td>
            );
          }
          return (
            <td key={labour.id} className={cellStyle}>
              {labour.localrate}
            </td>
          );
        })}
      </tr>
      <tr>
        <td className={headingStyle} xs={6}>
          Tax -WHT
        </td>
        {labourData.map((labour) => {
          if (Object.keys(labour).length === 1) {
            return (
              <td key={labour.id} className={cellStyle}>
                N/A
              </td>
            );
          }
          return (
            <td key={labour.id} className={cellStyle}>
              N/A
            </td>
          );
        })}
      </tr>
    </>
  );
}
