import { Card, Col, Row } from "react-bootstrap";

import RevenueChart from "./OpportunityCharts/RevenueChart/RevenueChart";
import LaborChart from "./OpportunityCharts/Labor/LaborChart";
import { useContext } from "react";
import ProjectInformation from "./Information/ProjectInformation";
import ClientInformation from "./Information/ClientInformation";
import { OpportunityContext } from "contexts/OpportunityProvider";
import { OpportunityDispatchContext } from "contexts/OpportunityProvider";
import { BreadcrumbDispatchContext } from "contexts/OpportunityProvider";
import { ActiveTabDispatchContext } from "contexts/OpportunityProvider";
import { useOpportunityCostingEquipments } from "contexts/OpportunityCostingProvider";

const Opportunity = () => {
  const opportunity = useContext(OpportunityContext);
  const setOpportunity = useContext(OpportunityDispatchContext);
  const setBreadcrumbs = useContext(BreadcrumbDispatchContext);
  const setActiveTab = useContext(ActiveTabDispatchContext);
  const opportunityCosting = useOpportunityCostingEquipments();

  if (opportunity) {
    const {
      id,
      updatedAt,
      title,
      sfNumber,
      projectStartDate,
      projectEndDate,
      inchargePartner,
      description,
      organization,
      clientContact,
      client,
      projectManager,
      projectLocation,
      clientEntityCountry,
      service,
      sector,
      tenderCompetition,
      pricingType,
      currency,
      subService,
      contractTerm,
      suppliersMarkup,
      expensesMarkup,
      apc,
    } = opportunity;

    setBreadcrumbs([
      { name: "Dashboard", link: "/" },
      { name: "Opportunities", active: "false" },
    ]);
    setActiveTab("dashboard");

    return (
      <>
        <Row className="my-2 py-3">
          <Col>
            <Card>
              <Card.Body>
                <span className="text-muted">Labour Cost</span>
                <h2>
                  {Intl.NumberFormat("en-us", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "USD",
                  }).format(opportunityCosting?.summary.labour.totalCost)}
                </h2>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card>
              <Card.Body>
                <span className="text-muted">Equipment & Supplies Cost</span>
                <h2>
                  {Intl.NumberFormat("en-us", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "USD",
                  }).format(
                    opportunityCosting?.summary.equipmentAndSupplies.totalCost,
                  )}
                </h2>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card>
              <Card.Body>
                <span className="text-muted">Digital Products Cost</span>
                <h2>
                  {Intl.NumberFormat("en-us", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "USD",
                  }).format(
                    opportunityCosting?.summary.digitalProduct.totalCost,
                  )}
                </h2>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card>
              <Card.Body>
                <span className="text-muted">Total Cost</span>
                <h2>
                  {Intl.NumberFormat("en-us", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "USD",
                  }).format(
                    opportunityCosting?.summary.equipmentAndSupplies.totalCost +
                      opportunityCosting?.summary.labour.totalCost +
                      opportunityCosting?.summary.digitalProduct.totalCost,
                  )}
                </h2>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={6}>
            <RevenueChart />
          </Col>
          <Col xs={12} sm={6}>
            <LaborChart />
          </Col>
        </Row>

        {/* <Alert variant="info" className={`my-3 d-flex flex-row`}>
          <div className="me-3">
            <span className="material-icons md-18 text-primary text-opacity-75">
              info
            </span>
          </div>
          <div>
            <h5 className="mb-1">
              <small>Missing Information</small>
            </h5>
            <p className="mb-1">
              <small>Please fill out the missing information</small>
            </p>
          </div>
        </Alert> */}
        <ProjectInformation
          opportunityId={id}
          information={{
            sfNumber,
            projectStartDate,
            projectEndDate,
            inchargePartner,
            clientContact,
            projectManager,
            projectLocation,
            pricingType,
            subService,
            tenderCompetition,
            contractTerm,
            currency,
          }}
          setOpportunityDetails={setOpportunity}
        />
        <ClientInformation
          opportunityId={id}
          information={{
            client,
            clientEntityCountry,
            service,
            sector,
            organization,
          }}
          setOpportunityDetails={setOpportunity}
        />
      </>
    );
  }
};

export default Opportunity;
