import { Card, Col, ProgressBar, Row } from "react-bootstrap";

import OpportunitiesChart from "./OpportunitiesChart/OpportunitiesChart";
import Opportunities from "./Opportunities/Opportunities";

const Dashboard = () => {
  return (
    <>
      <h1>Dashboard</h1>
      <Row className="my-3 py-3">
        <Col xs={12} sm={6} md={3}>
          <Card>
            <Card.Body>
              <h6>Opportunities</h6>
              <span className="fs-1">24</span>{" "}
              <span className="fs-6 ms-3">$13.79M</span>
            </Card.Body>
          </Card>{" "}
        </Col>
        <Col xs={12} sm={6} md={3}>
          <Card>
            <Card.Body>
              <h6>Qualified</h6>
              <span className="fs-1">4</span>
              <span className="fs-6 ms-3">$3.19M</span>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} sm={6} md={3}>
          <Card>
            <Card.Body>
              <h6>Costed</h6>
              <span className="fs-1">2</span>
              <span className="fs-6 ms-3">$1.09M</span>
              <span className="fs-6 ms-3 text-danger">
                $17%
                <span className="material-icons-outlined">arrow_drop_down</span>
              </span>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} sm={6} md={3}>
          <Card>
            <Card.Body>
              <h6>Approved</h6>
              <span className="fs-1">5</span>{" "}
              <span className="fs-6 ms-3">$5.10M</span>
              <span className="fs-6 ms-3 text-success">
                $17%
                <span className="material-icons-outlined">arrow_drop_up</span>
              </span>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} sm={6}>
          <Card className="mt-3 mb-3">
            <Card.Body>
              <h6>Cost Comparison</h6>
              <hr />
              <p className="mt-3 mb-1">Labor & WBS</p>
              <ProgressBar now={80} variant="danger" />

              <p className="mt-3 mb-1">Equipment & Supplies</p>
              <ProgressBar now={40} variant="warning" />

              <p className="mt-3 mb-1">Expenses</p>
              <ProgressBar now={70} variant="warning" />

              <p className="mt-3 mb-1">Labs</p>
              <ProgressBar now={5} variant="info" />

              <p className="mt-3 mb-1">Subcontractors</p>
              <ProgressBar now={20} variant="success" />
              <br />
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} sm={6}>
          <Card className="mt-3 mb-3">
            <Card.Body>
              <h6>% Approved over Time</h6>

              <hr />
              <OpportunitiesChart />
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Opportunities />
    </>
  );
};

export default Dashboard;
