import React, { useEffect, useContext } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
  Outlet,
} from "react-router-dom";
import "./App.css";

import Login from "./Login/Login";
import Logout from "./Logout/Logout";
import ForgotPassword from "./ForgotPassword/ForgotPassword";
import ResetPassword from "./ResetPassword/ResetPassword";
import NoMatch from "./NoMatch/NoMatch";

import Dashboard from "components/Dashboard/Dashboard";

import { UserContext, UserProvider } from "contexts/UserProvider";
import { OpportunityProvider } from "contexts/OpportunityProvider";
import Opportunity from "components/Opportunity/Opportunity";
import OpportunityWBS from "components/OpportunityWBS/OpportunityWBS";
import OpportunityCosting from "components/OpportunityCosting/OpportunityCosting";
import Header from "./Header/Header";
import { Container } from "react-bootstrap";
import OpportunityLayout from "components/Layouts/OpportunityLayout";

import { OpportunityCostingProvider } from "contexts/OpportunityCostingProvider";
import { PhasesProvider } from "contexts/PhasesProvider";
import PhasesLayout from "components/Layouts/PhasesLayout";
import OpportunitySummary from "components/OpportunitySummary/OpportunitySummary";

/**
 * Handles application level routing.
 * It sets a middleware for private and public routes.
 */
const App = () => {
  return (
    <div className="Main">
      <UserProvider>
        <Router>
          <ScrollToTop />
          <Routes>
            <Route
              path="/"
              element={
                <PrivateRouteLayout>
                  <PrivateRoute />
                </PrivateRouteLayout>
              }
            >
              <Route index element={<Dashboard />} />
              <Route
                path="opportunities/:opportunityId"
                element={
                  <OpportunityProvider>
                    <PhasesProvider>
                      <OpportunityCostingProvider>
                        <OpportunityLayout>
                          <PhasesLayout>
                            <Opportunity />
                          </PhasesLayout>
                        </OpportunityLayout>
                      </OpportunityCostingProvider>
                    </PhasesProvider>
                  </OpportunityProvider>
                }
              />
              <Route
                path="opportunities/:opportunityId/wbs"
                element={
                  <OpportunityProvider>
                    <PhasesProvider>
                      <OpportunityCostingProvider>
                        <OpportunityLayout>
                          <PhasesLayout>
                            <OpportunityWBS />
                          </PhasesLayout>
                        </OpportunityLayout>
                      </OpportunityCostingProvider>
                    </PhasesProvider>
                  </OpportunityProvider>
                }
              />
              <Route
                path="opportunities/:opportunityId/costing"
                element={
                  <OpportunityProvider>
                    <PhasesProvider>
                      <OpportunityCostingProvider>
                        <OpportunityLayout>
                          <PhasesLayout>
                            <OpportunityCosting />
                          </PhasesLayout>
                        </OpportunityLayout>
                      </OpportunityCostingProvider>
                    </PhasesProvider>
                  </OpportunityProvider>
                }
              />
              <Route
                path="opportunities/:opportunityId/summary"
                element={
                  <OpportunityProvider>
                    <PhasesProvider>
                      <OpportunityCostingProvider>
                        <OpportunityLayout>
                          <PhasesLayout>
                            <OpportunitySummary />
                          </PhasesLayout>
                        </OpportunityLayout>
                      </OpportunityCostingProvider>
                    </PhasesProvider>
                  </OpportunityProvider>
                }
              />
              {/* <Route path="opportunity/:opportunityId/value" element={<OpportunityValue/> } />  */}
            </Route>

            <Route path="login" element={<Login />} />
            <Route path="logout" element={<Logout />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="reset-password" element={<ResetPassword />} />
            <Route path="*" element={<NoMatch />} />
          </Routes>
        </Router>
      </UserProvider>
    </div>
  );
};

/** Middleware for private routes. */
const PrivateRoute = () => {
  const location = useLocation();
  const user = useContext(UserContext);
  return user ? <Outlet /> : <Navigate to="login" state={{ from: location }} />;
};

const PrivateRouteLayout = ({ children }) => {
  return (
    <>
      <Header />
      <Container className="my-5">{children}</Container>
    </>
  );
};

/** Scroll To Top on Navigation */
const ScrollToTop = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return null;
};

export default App;
