import axios from "axios";
import { MakeCancelable } from "./CancelablePromise";

/**
 * Handles API access to BEE.
 * Builds http requests via axios.
 * Adds Authorization headers and http errors interceptors.
 * Converts http requests to cancelable promises to allow cancellation of promises for /unmounted components.
 */

const END_POINT = process.env.REACT_APP_BEE_ENDPOINT;
const xsrfToken = Math.random().toString(36);

const httpOnlyConfig = {
  "XSRF-TOKEN": xsrfToken,
  version: "httpOnly",
};

var instance = axios.create({
  baseURL: END_POINT,
  headers: { common: { "XSRF-TOKEN": xsrfToken } },
  withCredentials: true,
  transformRequest: [
    (data) => {
      return data;
    },
    ...axios.defaults.transformRequest,
  ],
});

registerInterceptor(instance);

// Add a response interceptor for error handling
function registerInterceptor(instance) {
  instance.interceptors.response.use(
    function (response) {
      // Success
      return Promise.resolve(response.data);
    },
    function (error) {
      // Do something with response error
      if (error.response) {
        // The request was made and the server responded with a status code
        return Promise.reject({
          data: error.response.data,
          status: error.response.status,
          statusText: error.response.statusText,
        });
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        return Promise.reject({
          data: "",
          status: 0,
          statusText: "No Response",
        });
      } else {
        // Something happened in setting up the request that triggered an Error
        return Promise.reject({
          data: "",
          status: 0,
          statusText: error.message,
        });
      }
    },
  );
}

function login(data) {
  return instance
    .post("/login", {
      ...data,
      ...httpOnlyConfig,
    })
    .then((response) => {
      return Promise.resolve(response);
    });
}

function logout() {
  return instance
    .delete("/login", {
      params: { ...httpOnlyConfig },
    })
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function get(url, data) {
  return MakeCancelable(instance.get(url, { ...data, ...httpOnlyConfig }));
}

function post(url, data) {
  return MakeCancelable(
    instance.post(url, {
      ...data,
      ...httpOnlyConfig,
    }),
  );
}

function put(url, data) {
  return MakeCancelable(
    instance.put(url, {
      ...data,
      ...httpOnlyConfig,
    }),
  );
}

function destroy(url, data = {}) {
  return MakeCancelable(
    instance.delete(url, {
      params: httpOnlyConfig,
    }),
  );
}

function download(url, data) {
  return MakeCancelable(
    instance.get(
      url,
      Object.assign(
        {
          responseType: "blob",
          contentType: "application/octet-stream",
        },
        data,
      ),
    ),
  );
}

function isLoggedIn(data) {
  return false;
}

export { isLoggedIn, login, logout, post, get, put, destroy, download };
